.Container {
}
.Card {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
.Buttons {
}

.subtitle {
  margin-top: 20px;
}
