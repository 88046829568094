.Container{
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	background: white;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999999;
}
.Container > div{
	width: 200px;
	text-align: center;
}
.Displayed{
	opacity: 1;
	visibility: visible;
}
.Container img{
	display: block;
	filter: brightness(3);
	position: relative;
}
.Container h4{
	text-align: center;
	width: 100%;
	left: 0;
	color: #7f7f7f;
	top: calc(50% + 10px);
}
.Preloader{
	padding: 10px;
	position: relative;
}