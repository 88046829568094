.Container{
	display: flex;
	width: 100%;
	min-height: 100%;
	position: relative;
	align-items: center;
	justify-content: center;
}
.Content{
	display: block;
	text-align: center;
}
.Step{
	display: none;
	margin: 0px 10px;
}
.Displayed{
	display: block !important; 
}
.Text{
	margin: 10px;
}
.ButtonsContainer{
	width: 135px;
    margin: 20px auto 0px;
	display: flex;
	justify-content: space-between;
}
.AppnameContainer{
	display: flex;
	justify-content: center;
	margin: 15px auto 20px;
	align-items: flex-end;
}
.AppnameContainer button{
	margin: 0px 0px 0px 10px;
	width: 120px;
}
.LoadingMessage{
	margin: 20px;
    font-weight: bold;
    color: gray;
}

@media (max-width: 770px){
	.AppnameContainer{
		display: block;
	}
	.AppnameContainer button{
		width: 100%;
		margin: 10px 0px;
	}
}