.container{
    position: relative;
}
.animated{
    display: block;
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 0, 0, 0.6); 
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(255, 0, 0, 0.4);
    animation: pulse 1.5s infinite;
    z-index: 1000;
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }