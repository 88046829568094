.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.src-components-Login-Login-module__BottomOptions--32ZM1 {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 0px;
}
.src-components-Login-Login-module__BottomOptions--32ZM1 * {
  color: gray;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.src-components-Login-Login-module__BottomOptions--32ZM1 *:hover {
  color: #7ba932;
}
.src-components-Login-Login-module__Title--24jBc {
  margin: 25px;
  color: #9e9e9e;
}
.src-components-Login-Login-module__input--1Aybq {
  margin-bottom: 10px;
}
.src-components-Login-Login-module__form--39fH2 .ant-form-item-label {
  width: 100%;
  text-align: left;
  height: 14px;
  display: inline-flex;
  line-height: 14px;
}
.src-components-Login-Login-module__form--39fH2 .ant-form-explain {
  color: #f5222d;
  width: 100%;
  text-align: left;
  height: 10px;
  display: flex;
  font-size: 12px;
  line-height: 14px;
}


.LoginForm_Form__2tE7a{
	background: white;
	width: 100%;
	max-width: 400px;
	text-align: center;
	display: block;
	border-radius: 3px;
	z-index: 40;
}

.LoginForm_Form__2tE7a > img{
	width: 100px;
	margin: 50px auto 20px;
	display: block;
}

.LoginForm_Form__2tE7a > div{
	padding: 0px 30px 25px;
}

.LoginForm_Container__12TU5{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
	z-index: 35;
    position: relative;
}
.FullBackground_Background__2IvWh{
	position: fixed;
	z-index: 0;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: -webkit-radial-gradient(100% 0%, circle, #7ba932, #01594d);
	background: radial-gradient(circle at 100% 0%, #7ba932, #01594d);
	z-index: 30;
}
.ForgotPasswordDialog_Description__20EK5{
	margin: 0px 0px 20px;
}
.ForgotPasswordDialog_ButtonContainer__2gk3B{
	text-align: right;
}
.Input_error__3FZjh input, .Input_error__3FZjh textarea{
	border: 1px solid var(--error-color);
}
.Input_error__3FZjh p{
	color: var(--error-color);
    margin: 0px;
    font-size: 11px !important;
    text-align: left;
}
.Input_label__j7jgv{
    margin: 0px !important;
    font-size: 11px !important;
    text-align: left;
    color: var(--aic-secondary);
    font-weight: 500;
}
.src-components-Register-Register-module__BottomOptions--101us {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 0px;
}
.src-components-Register-Register-module__BottomOptions--101us * {
  color: gray;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.src-components-Register-Register-module__BottomOptions--101us *:hover {
  color: #7ba932;
}
.src-components-Register-Register-module__Title--1mDKa {
  margin: 25px;
  color: #9e9e9e;
}
.src-components-Register-Register-module__input--3Velm {
  margin-bottom: 10px;
}
.src-components-Register-Register-module__form--Q5Xyc .ant-form-item-label {
  width: 100%;
  text-align: left;
  height: 14px;
  display: inline-flex;
  line-height: 14px;
}
.src-components-Register-Register-module__form--Q5Xyc .ant-form-explain {
  color: #f5222d;
  width: 100%;
  text-align: left;
  height: 10px;
  display: flex;
  font-size: 12px;
  line-height: 14px;
}

.src-components-UI-LineCard-LineCard-module__Card--2r0Hg {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 15px 15px 10px;
  text-align: left;
  margin-bottom: 16px;
  background: white;
}
.src-components-UI-LineCard-LineCard-module__Shadow--3viDD {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #edeeef;
  border-radius: 3px;
  padding: 15px 15px 10px;
  text-align: left;
  box-shadow: 0px 1px 8px 0px #dcdcdc9f;
}
.src-components-UI-LineCard-LineCard-module__Card--2r0Hg h4 {
  color: #97c549;
  font-weight: 500;
  padding-bottom: 10px;
}
.src-components-UI-LineCard-LineCard-module__Card--2r0Hg p {
  margin: 0px 0px 20px;
}
.src-components-UI-LineCard-LineCard-module__Card--2r0Hg table {
  border-collapse: collapse;
  width: 100%;
}
.src-components-UI-LineCard-LineCard-module__Card--2r0Hg td {
  text-align: center;
  padding: 7px 0px;
}
.src-components-UI-LineCard-LineCard-module__Card--2r0Hg tr:not(:last-child) {
  border-bottom: 1px solid gainsboro;
}
.src-components-UI-LineCard-LineCard-module__Card--2r0Hg td:first-child {
  text-align: left;
}
.src-components-UI-LineCard-LineCard-module__Card--2r0Hg td:last-child {
  text-align: right;
  font-weight: 500;
}
.src-components-UI-LineCard-LineCard-module__description--1cMpt {
  margin-bottom: 10px !important;
}
.src-components-UI-LineCard-LineCard-module__Disabled--1_kPP {
  border: 1px dashed gray !important;
  background: transparent !important;
}
@media (max-width: 650px) {
  .src-components-UI-LineCard-LineCard-module__FullWidthTable--25S3J {
    width: calc(100vw - 15px);
    overflow-x: scroll;
  }
  .src-components-UI-LineCard-LineCard-module__FullWidthTable--25S3J table {
    width: 750px;
    overflow-x: scroll;
  }
}

.src-components-UI-File-File-module__Container--1hp3w {
  width: 100%;
  height: 110px;
  border-radius: 8px;
  text-align: center;
  border: 1px dashed lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: white;
}
.src-components-UI-File-File-module__Container--1hp3w img {
  width: 30px;
  height: 30px;
  -webkit-filter: unset;
          filter: unset;
  -webkit-transition: all 0s;
  transition: all 0s;
}
.src-components-UI-File-File-module__preview--2l8Wt {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0px;
  top: 0px;
  object-fit: cover;
  z-index: 100;
}
.src-components-UI-File-File-module__Container--1hp3w:not(.src-components-UI-File-File-module__Disabled--2xTWt):hover .src-components-UI-File-File-module__Icon--37-pM,
.src-components-UI-File-File-module__Container--1hp3w.src-components-UI-File-File-module__HasFiles--30J2X .src-components-UI-File-File-module__Icon--37-pM {
  -webkit-filter: sepia(1) hue-rotate(50deg) brightness(1.25) saturate(1.7);
          filter: sepia(1) hue-rotate(50deg) brightness(1.25) saturate(1.7);
}
.src-components-UI-File-File-module__Container--1hp3w p {
  padding: 0px 5px;
  color: inherit;
  -webkit-transition: all 0s;
  transition: all 0s;
  font-size: 14px;
  line-height: 19px;
}
.src-components-UI-File-File-module__Container--1hp3w p b {
  margin: 5px 10px;
  padding: 1px 5px;
  border-radius: 3px;
  background: #5c7d2b;
  color: white;
  z-index: 900;
  cursor: pointer;
  display: inline-block;
}
.src-components-UI-File-File-module__Container--1hp3w:not(.src-components-UI-File-File-module__Disabled--2xTWt):hover p,
.src-components-UI-File-File-module__Container--1hp3w.src-components-UI-File-File-module__HasFiles--30J2X p {
  color: #98cc6b;
}
.src-components-UI-File-File-module__Container--1hp3w input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 100;
  cursor: pointer;
}
.src-components-UI-File-File-module__preview--2l8Wt {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0px;
  top: 0px;
  object-fit: cover;
  z-index: 50;
}
.src-components-UI-File-File-module__Invalid--gZaQp img {
  -webkit-filter: sepia(1) hue-rotate(310deg) brightness(1.25) saturate(4) !important;
          filter: sepia(1) hue-rotate(310deg) brightness(1.25) saturate(4) !important;
}
.src-components-UI-File-File-module__Invalid--gZaQp p {
  color: #ff868a !important;
}
.src-components-UI-File-File-module__textError--3sN9U {
  color: #ff868a !important;
  font-size: 12px !important;
  text-align: left !important;
}
.src-components-UI-File-File-module__Modified--1b21p {
  background-color: #fddcc5 !important;
}
.src-components-UI-File-File-module__Modified--1b21p p {
  color: #000000 !important;
}
.src-components-UI-File-File-module__Modified--1b21p img {
  -webkit-filter: brightness(0.8) !important;
          filter: brightness(0.8) !important;
}
.src-components-UI-File-File-module__Modified--1b21p b {
  background: #626262 !important;
}
.src-components-UI-File-File-module__DisabledFilter--GTOk5 {
  background: transparent;
  position: absolute !important;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}
.src-components-UI-File-File-module__icons--1nZR2 {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 70;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: #ffffffcc;
}
.src-components-UI-File-File-module__Container--1hp3w:hover .src-components-UI-File-File-module__icons--1nZR2 {
  visibility: visible;
  opacity: 1;
}
.src-components-UI-File-File-module__icon--1uZ3O {
  border: 1px solid #757575;
  border-radius: 50px;
  height: 24px;
  width: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 3px 0px;
}
.src-components-UI-File-File-module__icon--1uZ3O > * {
  fill: #757575;
}
.src-components-UI-File-File-module__deleteIcon--2DAFg {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 5px;
  background: #9e9e9e;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.src-components-UI-File-File-module__Container--1hp3w:not(.src-components-UI-File-File-module__Disabled--2xTWt):hover .src-components-UI-File-File-module__deleteIcon--2DAFg {
  opacity: 1;
  visibility: visible;
}
.src-components-UI-File-File-module__Container--1hp3w p {
  margin: 0px;
}
.src-components-UI-File-File-module__Container--1hp3w a {
  top: 1px;
  position: relative;
}
.src-components-UI-File-File-module__CustomIcon--3U5Yr {
  border-radius: 4px !important;
  width: 40px !important;
  height: 40px !important;
}
.src-components-UI-File-File-module__Container--1hp3w input {
  z-index: 20;
}
.src-components-UI-File-File-module__progressContainer--1ZeyC {
  z-index: 80;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-UI-File-File-module__inputContainer--1aHuT {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.src-components-UI-File-File-module__video--3uM8Z {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0px;
  top: 0px;
  z-index: 50;
  object-fit: cover;
}

.src-components-Distribution-AppFormHome-FormStepper-FormSteps-UploadApk-UploadApk-module__newVersion--1WoY6 p {
  margin-bottom: 10px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-UploadApk-UploadApk-module__newVersion--1WoY6 .ant-row.ant-form-item {
  display: flex;
  justify-content: start;
  margin: 0px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-UploadApk-UploadApk-module__newVersion--1WoY6 .ant-row.ant-form-item label {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65) !important;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-UploadApk-UploadApk-module__newVersion--1WoY6 .ant-input.ant-input-disabled {
  border: none;
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  background-color: white;
  padding: 0px;
  font-weight: 300;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-UploadApk-UploadApk-module__newVersion--1WoY6 .ant-form-item-label {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__container--23mvh {
  margin-top: 15px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__container--23mvh .src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__noDots--27OIK b {
  font-weight: 700;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__container--23mvh .src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__noDots--27OIK ::after {
  display: none !important;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__container--23mvh .ant-form-item-label,
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__container--23mvh .ant-col.ant-form-item-label,
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-AppInformation-AppInformation-module__container--23mvh .ant-form-item-required {
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
}

.src-components-Distribution-AppFormHome-FormStepper-FormSteps-Screenshots-Screenshots-module__container--2v7Go .ant-alert {
  width: 100% !important;
  margin-bottom: 16px !important;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-Screenshots-Screenshots-module__container--2v7Go .src-components-Distribution-AppFormHome-FormStepper-FormSteps-Screenshots-Screenshots-module__b--1sufP {
  text-align: start;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-Screenshots-Screenshots-module__description--3FVC0 {
  text-align: left;
  margin-top: 15px;
}
@media (max-width: 767px) {
  .src-components-Distribution-AppFormHome-FormStepper-FormSteps-Screenshots-Screenshots-module__preview--qM_0S {
    margin-top: 15px !important;
  }
}


.src-components-Distribution-AppFormHome-FormStepper-FormSteps-DistributionDetails-DistributionDetails-module__container--tMZaY {
  margin-top: 15px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-DistributionDetails-DistributionDetails-module__container--tMZaY .ant-form-item-label,
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-DistributionDetails-DistributionDetails-module__container--tMZaY .ant-col.ant-form-item-label,
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-DistributionDetails-DistributionDetails-module__container--tMZaY .ant-form-item-required {
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
}

.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__container--1a_wk {
  margin-top: 15px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__container--1a_wk .ant-form-item-label,
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__container--1a_wk .ant-col.ant-form-item-label,
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__container--1a_wk .ant-form-item-required {
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__privacyExample--2PTvf {
  position: relative;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__privacyExample--2PTvf img {
  display: block;
  width: 100%;
  position: relative;
  height: auto;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__privacyExample--2PTvf label {
  position: absolute;
  background: #0000004f;
  width: calc(100% - 6px);
  left: 3px;
  text-align: center;
  top: 4px;
  padding: 4px 0px;
  font-weight: bold;
  color: white;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__intro--H6dtU {
  margin: 0px 10px 20px !important;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__scrollable--2dz86 {
  position: relative;
  height: 485px;
  overflow-y: scroll;
  border: 1px solid black;
  margin-top: 8px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__scrollable--2dz86 img {
  display: block;
  width: 100%;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__scrollable--2dz86::-webkit-scrollbar {
  -webkit-appearance: none;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__scrollable--2dz86::-webkit-scrollbar:vertical {
  width: 11px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__scrollable--2dz86::-webkit-scrollbar:horizontal {
  height: 11px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__scrollable--2dz86::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid black;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__clickable--3fZTg {
  color: #699622;
  font-weight: 700;
  border-radius: 3px;
  text-decoration: underline;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__requirements--1ahpT b,
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__requirements--1ahpT a {
  font-weight: 700;
}
.src-components-Distribution-AppFormHome-FormStepper-FormSteps-PrivacyPolicy-PrivacyPolicy-module__download--PPITt {
  color: #699622;
  font-weight: 700;
  border-radius: 3px;
  text-decoration: underline;
}

.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__buttons--1RUi0 {
  margin-top: 15px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__previousButton--2-9Pa {
  margin-right: 20px;
}
.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C {
  display: flex;
}
.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C .ant-steps {
  text-align: left;
  position: fixed;
  top: 190px;
  left: 270px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C .ant-steps + div {
  padding-left: 160px;
  width: 100%;
}
.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C .ant-steps-item-container {
  cursor: pointer;
}
.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C.src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__horizontal--1KQfo {
  display: flex;
}
@media (max-width: 960px) {
  .src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C {
    display: block !important;
  }
  .src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C .ant-steps {
    position: relative;
    top: unset;
    left: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: contents;
  }
  .src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C .ant-steps + div {
    padding-left: 0px !important;
  }
}
@media (max-width: 760px) {
  .src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__buttons--1RUi0 {
    padding-top: 15px;
  }
  .src-components-Distribution-AppFormHome-FormStepper-FormStepper-module__container--2lc7C {
    margin-top: -10px !important;
  }
}

.src-components-Distribution-AppFormHome-SubmitNewVersion-SubmitNewVersion-module__button--6yiXF {
  margin-top: 30px;
}

.src-components-Distribution-AppFormHome-EditAppInformation-EditAppInformation-module__button--wVyRk {
  margin-top: 30px;
}

.src-components-Distribution-AppFormHome-AppDocuments-AppDocuments-module__header--1x8-F {
  margin-top: 20px;
}
.src-components-Distribution-AppFormHome-AppDocuments-AppDocuments-module__header--1x8-F h3 {
  text-transform: capitalize;
}

.src-components-Distribution-AppFormHome-AppDocuments-AttachmentCard-AttachmentCard-module__container--2TotL {
  padding: 20px 40px 20px 0px;
}
.src-components-Distribution-AppFormHome-AppDocuments-AttachmentCard-AttachmentCard-module__Button--srgDw {
  margin-bottom: 20px;
  margin-top: 20px;
}

.NewFeature_container__2WOIT{
    position: relative;
}
.NewFeature_animated__Od6tf{
    display: block;
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 0, 0, 0.6); 
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(255, 0, 0, 0.4);
    -webkit-animation: NewFeature_pulse__DrRWV 1.5s infinite;
            animation: NewFeature_pulse__DrRWV 1.5s infinite;
    z-index: 1000;
}

@-webkit-keyframes NewFeature_pulse__DrRWV {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }

@keyframes NewFeature_pulse__DrRWV {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }
.src-components-Distribution-AppFormHome-ApkReclaim-ApkReclaim-module__container--J6utF {
  padding: 30px;
}
.src-components-Distribution-AppFormHome-ApkReclaim-ApkReclaim-module__globalSave--1Jj5q {
  position: fixed !important;
  padding: 10px;
  left: 240px;
  width: 100%;
  background-color: white;
  z-index: 90;
  border-bottom-color: gainsboro;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 144px;
}
.src-components-Distribution-AppFormHome-ApkReclaim-ApkReclaim-module__introText--mC8SU {
  text-align: left;
}

.src-components-Distribution-AppFormHome-ApkReclaim-AttachmentCard-AttachmentCard-module__container--iXvHE {
  padding: 0px 10px 20px 0px;
}
.src-components-Distribution-AppFormHome-ApkReclaim-AttachmentCard-AttachmentCard-module__Button--2Dzl8 {
  margin: 10px 0px;
}
.src-components-Distribution-AppFormHome-ApkReclaim-AttachmentCard-AttachmentCard-module__Button--2Dzl8 h3 {
  margin-bottom: 0px;
}

.src-components-Distribution-AppFormHome-AppFormHome-module__container--3ha3p .ant-tabs-bar {
  margin: 0px !important;
  position: fixed;
  z-index: 200;
  top: 93px;
  background: white;
  width: calc(100% - 232px);
  right: 0px;
}
.src-components-Distribution-AppFormHome-AppFormHome-module__container--3ha3p .ant-tabs-tab {
  margin-right: 8px !important;
}
.src-components-Distribution-AppFormHome-AppFormHome-module__container--3ha3p .src-components-Distribution-AppFormHome-AppFormHome-module__options--iOPty {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, calc(50% - 8px));
}
.src-components-Distribution-AppFormHome-AppFormHome-module__container--3ha3p .src-components-Distribution-AppFormHome-AppFormHome-module__disabledLabel--3-hDM {
  padding-top: 16px;
  font-size: 12px;
  display: block;
}
@media (max-width: 800px) {
  .src-components-Distribution-AppFormHome-AppFormHome-module__container--3ha3p {
    padding-top: 10px;
  }
  .src-components-Distribution-AppFormHome-AppFormHome-module__container--3ha3p .ant-tabs-bar {
    width: 100%;
    top: 93px;
  }
}

.src-components-Distribution-ClientForms-module__Form--2alXO b {
  font-weight: 700;
}
.src-components-Distribution-ClientForms-module__Form--2alXO .ant-form-vertical .ant-form-item-label {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #ef5659;
}
.src-components-Distribution-ClientForms-module__Form--2alXO .ant-form-item-control-wrapper {
  text-align: justify;
}
.src-components-Distribution-ClientForms-module__Form--2alXO .anticon-question-circle {
  color: gray;
}
.src-components-Distribution-ClientForms-module__Buttons--2yg3a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.src-components-Distribution-ClientForms-module__Buttons--2yg3a button {
  margin-right: 10px;
}
.src-components-Distribution-ClientForms-module__securityForm--1Wofw .ant-form-item-label {
  font-weight: 400;
}
.src-components-Distribution-ClientForms-module__exampleImage--2aChV {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.src-components-Distribution-ClientForms-module__exampleImage--2aChV label {
  position: absolute;
  bottom: 0px;
  z-index: 100;
  background: #00000078;
  width: 100%;
  text-align: center;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}
@media (max-width: 800px) {
  .src-components-Distribution-ClientForms-module__Form--2alXO {
    padding: 0px;
  }
}
@media (max-width: 500px) {
  .src-components-Distribution-ClientForms-module__Buttons--2yg3a {
    flex-direction: column;
  }
}

.src-components-DemoAppAlert-DemoAppAlert-module__container--3rviF {
  margin: 0px 0px 5px;
}
.src-components-DemoAppAlert-DemoAppAlert-module__container--3rviF * {
  text-align: left;
}
.src-components-DemoAppAlert-DemoAppAlert-module__container--3rviF a {
  font-weight: bold;
}
.src-components-DemoAppAlert-DemoAppAlert-module__container--3rviF p {
  margin: 0;
}

.src-components-Distribution-Distribution-module__errorMessage--L3ijt .ant-alert {
  margin-bottom: 16px;
  text-align: left;
}
.src-components-Distribution-Distribution-module__wrapper--FhwMF {
  padding: 40px 0px;
}

.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__Container--2TxQK {
  text-align: left;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__Container--2TxQK .ant-table-scroll table {
  overflow: hidden !important;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__Pdf--IAJ1g {
  margin-top: 20px;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__Pdf--IAJ1g .ant-btn {
  margin-top: 20px;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__Pdf--IAJ1g .ant-card-cover {
  height: 736px;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__actionsButtons--3kEvz {
  display: flex;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__downloadButton--206FL {
  max-width: 214px;
  margin-right: 10px;
  margin-left: 24px;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__table--1aYBT {
  margin-top: 20px;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__previewButton--16whM .ant-btn {
  top: -8px;
  left: -6px;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__downloadButton--206FL .ant-btn {
  left: -13px;
}
.src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__tableActions--3hNL8 {
  background: chartreuse;
}
@media (max-width: 440px) {
  .src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__actionsButtons--3kEvz {
    flex-direction: column;
    align-items: center;
  }
  .src-components-Distribution-AuthorizationLetter-AuthorizationLetter-module__downloadButton--206FL {
    margin-bottom: 10px;
  }
}

.src-components-UI-PreviewButton-PreviewButton-module__Container--3EDnu {
  width: 100%;
  border-radius: 6px;
  /* background: #f6f6f6; */
  padding: 8px;
  /* display: flex;	
	justify-content: space-between;
	align-items: center; */
  height: 40px;
}
/* .Container > div{
	display: flex;
	align-items: center;
	width: 62px;
	justify-content: space-between;
} */
.src-components-UI-PreviewButton-PreviewButton-module__Container--3EDnu a {
  display: flex;
  align-items: center;
}
.src-components-UI-PreviewButton-PreviewButton-module__Container--3EDnu svg {
  opacity: 0.9;
}
.src-components-UI-PreviewButton-PreviewButton-module__label--3wmvL {
  margin: 0 !important;
  font-size: 16px;
}
.src-components-UI-PreviewButton-PreviewButton-module__modified--3yZ4o,
.src-components-UI-PreviewButton-PreviewButton-module__modified--3yZ4o label {
  background: #fddcc5;
}
.src-components-UI-PreviewButton-PreviewButton-module__document--m_58X {
  overflow: auto;
  height: 700px;
}
.src-components-UI-PreviewButton-PreviewButton-module__document--m_58X .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.src-components-UI-PreviewButton-PreviewButton-module__document--m_58X .react-pdf__Page {
  margin: 10px 0px 10px;
  box-shadow: 0px 0px 12px 0px #0000002e;
  border: 1px solid #e2e2e2;
}

.Tooltip_Container__16Zsn{
	display: inline-flex;
	height: auto;
	align-items: center;
	width: auto;
	text-align: center;
	margin: 0px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	position: relative;
}
.Tooltip_Tooltip__2F7jy{
	display: block;
	position: absolute;
	min-width: 200px;
	background: #2f2f2f;
	color: white;
	border-radius: 4px;
	padding: 15px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.1s 0.1s;
	transition: all 0.1s 0.1s;
	z-index: 100;
	top: calc(100% + 10px);
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	left: 50%;
	font-weight: 300 !important;
}
.Tooltip_Tooltip__2F7jy span{
	color:white;
}
.Tooltip_Tooltip__2F7jy a{
	color: #81bbef;
	display: inline-block;
}
.Tooltip_Tooltip__2F7jy img{
	position: relative;
	width: 100% !important;
    height: auto !important;
}
.Tooltip_Tooltip__2F7jy i{
	display: block;
	width: 0;
	height: 0;
	border: 10px solid #00000000;
	border-bottom: 10px solid black;
	position: absolute;
	left: calc(50% - 10px);
	top: -20px;
}
.Tooltip_Container__16Zsn:hover{
	z-index: 1200;
}
.Tooltip_Container__16Zsn:hover .Tooltip_Tooltip__2F7jy{
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__Container--2Vam4 .ant-list-item-meta-title {
  color: #777777;
  background: #f5f5f5;
  padding: 0px 5px;
  display: inline-block;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid gainsboro;
  margin-top: 5px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__Container--2Vam4 .ant-list-item {
  padding: 6px 0px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__Container--2Vam4 .ant-list-item-meta-description {
  color: #3f3f3f;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__Container--2Vam4 .ant-list-item-meta-description span {
  display: block;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__clickable--2Eyb6 {
  cursor: pointer;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__intro--6Z_Ki {
  padding: 2px 0px 10px;
  text-align: left;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__intro--6Z_Ki p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__intro--6Z_Ki p b {
  font-weight: 500;
  color: #1b90ff;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__alert--gl4iC {
  text-align: left;
  padding-bottom: 10px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__problemText--3JM_6 {
  max-width: 200px;
  display: block;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__MainInfo--2atKX {
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 3px;
  text-align: left;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__MainInfo--2atKX b {
  font-weight: 700;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__MainInfo--2atKX h2 {
  padding: 0px 0px 15px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__MainInfo--2atKX h3 {
  padding: 5px;
  font-weight: 500;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__Button--GvsfF {
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__StoresTitle--1cIrv {
  color: #97c549;
  font-weight: 500;
  padding: 15px 15px 10px;
  margin: 0;
  text-align: left;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__reviews--14IhF {
  cursor: pointer;
  background-color: #8dbe40;
  color: white;
  display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 5px;
  padding: 0 4px 0 4px;
  text-align: center;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__UpdateInfo--1-J66 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f0f0f0;
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 3px;
  text-align: left;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__UpdateInfo--1-J66 b {
  font-weight: 700;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__UpdateInfo--1-J66 h2 {
  padding: 0px 0px 15px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__UpdateInfo--1-J66 h3 {
  padding: 5px;
  font-weight: 500;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__completed---w6w0 h3 {
  background: #4ac547;
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__pending--2NC23 h3 {
  background: #e2cb4a;
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__rejected--3Zomn h3 {
  background: #ef5659;
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__Table--3OLq9 {
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__Table--3OLq9 th {
  background: lightgray;
  padding: 10px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__TableTitle--3DzNZ {
  margin: 10px 0px;
  text-align: left;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__OtherStores--_0OI3 {
  margin-top: 50px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__AlignLeft--3Vfju {
  text-align: left !important;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__TitleTooltip--30Q0_ {
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__InfoIcon--2j5st {
  display: inline-block;
  width: 16px;
  height: 16px;
  opacity: 0.6;
  margin: 0px 5px;
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__CopyrightWarning--3fauG {
  margin: 0px 0px 30px;
  padding: 10px;
  border-radius: 3px;
  border: 2px solid #ef5759;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto 140px;
  justify-content: space-between;
  align-items: center;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__CopyrightWarning--3fauG span {
  color: #ef5756 !important;
  text-align: justify;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__CopyrightWarning--3fauG a {
  width: auto;
  display: block;
  padding: 10px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  margin: 0px 0px 0px 15px;
  background: #ef5659;
  border-radius: 3px;
  color: white;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__UpdateButton--2OAn9 {
  display: block;
  text-align: right;
  margin-bottom: 30px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__downloadsHeader--20UX9 {
  display: inline-block;
  width: 80px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__LineCardButton--1BEx0 {
  cursor: pointer;
  background-color: #8dbe40;
  color: white;
  display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 5px;
  padding: 0 4px 0 4px;
  text-align: center;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__downloadsFilter--3OfxA {
  display: flex;
  flex-direction: column;
  width: 600px;
}
.src-components-Distribution-DistributionDetails-DistributionDetails-module__TwoCards--1tU49 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
@media (max-width: 1000px) {
  .src-components-Distribution-DistributionDetails-DistributionDetails-module__Table--3OLq9 {
    width: 100%;
    position: relative;
    display: block;
    border-top: 1px solid lightgray;
  }
  .src-components-Distribution-DistributionDetails-DistributionDetails-module__Table--3OLq9 thead {
    display: none;
  }
  .src-components-Distribution-DistributionDetails-DistributionDetails-module__Table--3OLq9 tbody {
    width: 100%;
    display: block;
  }
  .src-components-Distribution-DistributionDetails-DistributionDetails-module__CopyrightWarning--3fauG {
    display: block;
  }
  .src-components-Distribution-DistributionDetails-DistributionDetails-module__CopyrightWarning--3fauG a {
    width: auto;
    text-align: center;
    margin: 10px 0px 0px;
  }
  .src-components-Distribution-DistributionDetails-DistributionDetails-module__MAU--14Az5 {
    display: none;
  }
}
@media (max-width: 600px) {
  .src-components-Distribution-DistributionDetails-DistributionDetails-module__TwoCards--1tU49 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__container--1u5JI .ant-card-bordered {
  margin-bottom: 20px;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__intro--4Z530 {
  text-align: left;
  margin-bottom: 20px;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__intro--4Z530 .src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__flex--1U8ia {
  display: block;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__intro--4Z530 img {
  width: 130px;
  display: inline-block;
  margin: 8px 8px 0px 0px;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__intro--4Z530 p {
  margin: 10px 0px;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__intro--4Z530 p a {
  font-weight: 700;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__modalBadges--33CTc {
  position: relative;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__modalBadges--33CTc img {
  width: 100%;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__modalBadges--33CTc a {
  font-size: 11px;
  text-decoration: underline;
  color: #2f2f2f;
  display: block;
  width: 100%;
  text-align: center;
  padding: 6px 0px 10px;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__modalBadges--33CTc p {
  margin: 16px 0px 0px;
  font-weight: 300;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__modalBadges--33CTc textarea {
  resize: none;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__qrCode--3pRsd {
  display: flex;
  justify-content: center;
}
.src-components-Distribution-DistributionDetails-AppInChinaBadge-AppInChinaBadge-module__htmlTag--qaDxA {
  word-break: break-word;
}

.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__container--Z1cTY {
  margin-top: 20px;
  text-align: left;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__container--Z1cTY h3 {
  color: #97c549;
  font-weight: 800;
  margin-bottom: 0px;
  margin-top: 8px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__card--1Q0vb {
  margin-top: 20px !important;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__card--1Q0vb .ant-card-body {
  padding: 10px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__card--1Q0vb .ant-table-wrapper {
  border-top: 1px solid gainsboro;
  margin-bottom: 8px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__card--1Q0vb .ant-table-tbody td {
  padding: 8px !important;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__title--jFbq_ {
  margin-bottom: 20px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsTable-module__globalSave--3LyjD {
  position: fixed !important;
  padding: 10px;
  left: 240px;
  width: 100%;
  top: 189px;
  background-color: white;
  z-index: 90;
  border-bottom-color: gainsboro;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__container--2RVtQ {
  margin-top: 20px;
  text-align: left;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__container--2RVtQ h3 {
  color: #97c549;
  font-weight: 800;
  margin-bottom: 0px;
  margin-top: 8px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__card--16-Q9 {
  margin-top: 20px !important;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__card--16-Q9 .ant-card-body {
  padding: 10px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__card--16-Q9 .ant-table-wrapper {
  border-top: 1px solid gainsboro;
  margin-bottom: 8px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__card--16-Q9 .ant-table-tbody td {
  padding: 8px !important;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__title--Zy_ND {
  margin-bottom: 20px;
}
.src-components-Distribution-DistributionDetails-DistributionDetailsTable-DistributionDetailsCard-DistributionDetailsCard-module__globalSave--2FHjN {
  position: fixed !important;
  padding: 10px;
  left: 240px;
  width: 100%;
  top: 189px;
  background-color: white;
  z-index: 90;
  border-bottom-color: gainsboro;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.src-components-Distribution-DistributionDetails-DistributionDetailsTable-HistoryTable-HistoryTable-module__problemText--2Z8pC {
  max-width: 200px;
  display: block;
}

.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__container--1yBQw {
  position: relative;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh {
  position: relative;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh .ant-carousel .slick-slide > div {
  height: 500px;
  background: #f5f5f5;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh .ant-carousel .slick-slide > div img {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: top;
  background-position: top;
  border: 1px solid #f3f3f3;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh .ant-btn-background-ghost.ant-btn-primary {
  background: #9e9e9e !important;
  text-shadow: none;
  opacity: 0.6;
  color: white !important;
  border-color: white !important;
  font-size: 20px;
  line-height: 30px;
  border-width: 2px;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh .ant-btn,
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh .ant-btn:hover {
  display: block;
  z-index: 3;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__left--1x3kv .ant-btn {
  position: absolute;
  left: 10px;
  top: calc(50% - 18px);
  z-index: 200;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__left--1x3kv .ant-btn > .anticon {
  left: -1px;
  position: relative;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__right--zoHdl .ant-btn {
  position: absolute;
  right: 10px;
  top: calc(50% - 18px);
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__right--zoHdl .ant-btn > .anticon {
  right: -1px;
  position: relative;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__carouselBlock--2Om2A {
  width: 100%;
  display: inline-block;
  height: 100%;
  position: relative;
}
.src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__carouselBlock--2Om2A p {
  margin: 0;
  position: absolute;
  padding: 10px;
  width: 100%;
  bottom: 0px;
  background: #e8e8e8;
  text-align: center;
  font-weight: 500;
}
@media (max-width: 800px) {
  .src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh {
    position: relative;
  }
  .src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh .ant-carousel .slick-slide > div {
    height: 400px;
  }
  .src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__container--1yBQw .ant-modal-body {
    padding: 10px;
  }
  .src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__container--1yBQw .ant-carousel .slick-slide > div {
    height: 320px;
    background: black;
  }
}
@media (max-width: 650px) {
  .src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh {
    position: relative;
  }
  .src-components-Distribution-AppFormHome-AppFormIntro-AppFormIntro-module__content--1Z0Fh .ant-carousel .slick-slide > div {
    height: 300px;
  }
}

.src-components-Localization-AppTesting-AppTesting-module__Container--A2zB6 {
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
}
.src-components-Localization-AppTesting-AppTesting-module__Container--A2zB6 .ant-form-vertical .ant-form-item-label {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.src-components-Localization-AppTesting-AppTesting-module__Container--A2zB6 > p {
  text-align: justify;
  margin-bottom: 16px;
}
.src-components-Localization-AppTesting-AppTesting-module__Container--A2zB6 > h3 {
  color: #97c549;
  text-align: left;
}
.src-components-Localization-AppTesting-AppTesting-module__Container--A2zB6 > ul > li {
  list-style: none;
  text-align: left;
}
.src-components-Localization-AppTesting-AppTesting-module__Container--A2zB6 > ul > li::before {
  content: "• ";
  color: #97c549;
}
.src-components-Localization-AppTesting-AppTesting-module__ApkButton--2W1PT {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0px 15px;
}
.src-components-Localization-AppTesting-AppTesting-module__Grid--3rj5E {
  display: grid;
  grid-template-columns: 40% auto;
  grid-gap: 20px;
}
.src-components-Localization-AppTesting-AppTesting-module__sdkList--2L0-q > h3 {
  color: #97c549;
  margin: 0;
}
.src-components-Localization-AppTesting-AppTesting-module__sdkList--2L0-q > ul {
  padding: 0;
}
.src-components-Localization-AppTesting-AppTesting-module__sdkList--2L0-q > ul > h4 {
  text-align: left;
  margin: 0;
  padding: 0;
}
.src-components-Localization-AppTesting-AppTesting-module__sdkList--2L0-q > ul > li {
  text-align: left;
  list-style-type: none;
}

.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 {
  padding-bottom: 20px;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 .ant-steps,
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 .ant-steps-horizontal,
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 .ant-steps-small,
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 .ant-steps-label-horizontal {
  text-align: justify;
  padding: 30px 0px;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 .ant-form > p {
  text-align: left;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 .ant-form > p > b {
  color: #97c549;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 > p {
  text-align: justify;
  margin-bottom: 16px;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 > h3 {
  color: #97c549;
  text-align: left;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 > ul > li {
  list-style: none;
  text-align: left;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Container--25nS7 > ul > li::before {
  content: "• ";
  color: #97c549;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__ApkButton--1njhm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 15px;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Grid--2U3Ej {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-gap: 10px;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__GridContainer--3SsOh {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 10px;
}
.src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__AddButton--3wXmD {
  padding-top: 35px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 875px) {
  .src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Grid--2U3Ej {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__AddButton--3wXmD {
    padding-top: 30px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 570px) {
  .src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__Grid--2U3Ej {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__GridContainer--3SsOh {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .src-components-Localization-AppTesting-ApkInspector-ApkInspector-module__AddButton--3wXmD {
    padding-top: 0;
    display: flex;
    justify-content: center;
    margin-top: -5px !important;
  }
}
@media (max-width: 480px) {
  .ant-steps, .ant-steps-horizontal, .ant-steps-small, .ant-steps-label-horizontal {
    padding: 30px 0px 0px !important;
  }
}

.src-components-Localization-Translation-Translation-module__Container--6UK5X .ant-form-vertical .ant-form-item-label {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.src-components-Localization-Translation-Translation-module__Container--6UK5X > p {
  text-align: justify;
  margin-bottom: 16px;
}

.src-components-Localization-TrademarkSearch-TrademarkSearch-module__container--2_82S .ant-table-tbody > tr > td {
  padding: 8px 8px !important;
}
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__container--2_82S a {
  color: #75a02c !important;
  font-weight: 500;
}
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__search--1bui3 {
  margin-bottom: 20px;
}
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__img--3yrSu {
  display: inline-block;
  height: 50px;
  width: 90px !important;
  object-fit: contain;
  object-position: center;
}
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__results--3L9Xu {
  margin-bottom: 20px !important;
}
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__results--3L9Xu p {
  margin: 0;
  text-align: left;
}
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__Introduction--1v_9z,
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__Alert--2QlKk {
  text-align: left;
  margin-bottom: 20px !important;
}
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__Introduction--1v_9z p,
.src-components-Localization-TrademarkSearch-TrademarkSearch-module__Alert--2QlKk p {
  margin: 0;
}

.SetupApp_Container__34rXx{
	display: flex;
	width: 100%;
	min-height: 100%;
	position: relative;
	align-items: center;
	justify-content: center;
}
.SetupApp_Content__3c5mi{
	display: block;
	text-align: center;
}
.SetupApp_Step__2I0S1{
	display: none;
	margin: 0px 10px;
}
.SetupApp_Displayed__2gXKl{
	display: block !important; 
}
.SetupApp_Text__257Ed{
	margin: 10px;
}
.SetupApp_ButtonsContainer__2fIVo{
	width: 135px;
    margin: 20px auto 0px;
	display: flex;
	justify-content: space-between;
}
.SetupApp_AppnameContainer__2WxPQ{
	display: flex;
	justify-content: center;
	margin: 15px auto 20px;
	align-items: flex-end;
}
.SetupApp_AppnameContainer__2WxPQ button{
	margin: 0px 0px 0px 10px;
	width: 120px;
}
.SetupApp_LoadingMessage__eAFCA{
	margin: 20px;
    font-weight: bold;
    color: gray;
}

@media (max-width: 770px){
	.SetupApp_AppnameContainer__2WxPQ{
		display: block;
	}
	.SetupApp_AppnameContainer__2WxPQ button{
		width: 100%;
		margin: 10px 0px;
	}
}
.GoogleSearcher_Text__2JGn0{
	margin: 0px;
}
.GoogleSearcher_AppnameContainer__2fW-4{
	display: flex;
	justify-content: center;
	margin: 15px auto 20px;
	align-items: flex-end;
}
.GoogleSearcher_AppnameContainer__2fW-4 button{
	margin: 0px 0px 0px 10px;
	width: 120px;
}

@media (max-width: 770px){
	.GoogleSearcher_AppnameContainer__2fW-4{
		display: block;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
    	margin: auto;
	}
	.GoogleSearcher_AppnameContainer__2fW-4 button{
		width: 100%;
		margin: 10px 0px;
	}
}
.GoogleAppsDialog_Row__2AxEc{
	display: flex;
	background: whitesmoke;
	margin: 10px 0px;
	padding: 10px;
	border-radius: 3px;
	cursor: pointer;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.GoogleAppsDialog_Row__2AxEc:hover{
	background: #f0f0f0;
}
.GoogleAppsDialog_Row__2AxEc > img{
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	margin-right: 10px;
	background: white;
}
.GoogleAppsDialog_Row__2AxEc > div{
	display: grid;
}
.GoogleAppsDialog_Row__2AxEc > div span{
	display: flex;
	align-items: center;
	padding: 0px 5px;
	font-weight: 700;
	text-align: left;
}
.GoogleAppsDialog_Company__25xTX{
	font-size: 12px;
	font-weight: 300 !important;
}
.GoogleAppsDialog_Title__1WpH0{
	margin: 10px;
	text-align: center;
}
.GoogleAppsDialog_NotMyApp__eVasV{
	background: #c2c2c2;
	justify-content: center;
}
.GoogleAppsDialog_Loader__3vjKl{
	padding: 40px 0px;
}
.GoogleAppsDialog_Loader__3vjKl p{
	text-align: center;
	padding: 10px 0px;
}

.Dialog_Dialog__Hdp_X{
	position: fixed;
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	left: 0;
	opacity: 0;
	visibility: hidden;
	top: 0;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.Dialog_Dialog__Hdp_X.Dialog_Displayed__1GRxt{
	visibility: visible;
	opacity: 1;
}
.Dialog_Backdrop__1gRJ4{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 200;
	background: #00000044;
}
.Dialog_Container__t7BaN{
	top: 20px;
	width: 100%;
	max-width: 600px;
	background: white;
	border-radius: 3px;
	padding: 30px;
	z-index: 400;
	box-shadow: 0 0 40px 0 #00000052;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	max-height: calc(100vh - 60px);
}
.Dialog_Dialog__Hdp_X.Dialog_Displayed__1GRxt .Dialog_Container__t7BaN{
	top: 0px;
}
.Dialog_CloseButton__2s3Vs{
	position: absolute;
    width: 20px;
    height: 20px;
    padding: 5px;
    border: 2px solid #828282;
    border-radius: 50%;
    background: white;
    z-index: 9999;
    right: 10px;
    top: 10px;
    display: none;
}

@media (max-width: 770px){
	.Dialog_Container__t7BaN{
		width: 100% !important;
	    max-height: calc(100% - 60px);
	    overflow: auto;
	    border-radius: 0px;
	}
	.Dialog_CloseButton__2s3Vs{
		display: block;
	}
}
.Preloader_Loader__1OSik,
.Preloader_Loader__1OSik:before,
.Preloader_Loader__1OSik:after {
  border-radius: 50%;
}
.Preloader_Loader__1OSik {
  color: #8dbd40;
  font-size: 11px;
  text-indent: -99999em;
  margin: 0 auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.Preloader_Loader__1OSik:before,
.Preloader_Loader__1OSik:after {
  position: absolute;
  content: '';
}
.Preloader_Loader__1OSik:before {
  width: 5.2em;
  height: 10.2em;
  background: white;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
          transform-origin: 5.2em 5.1em;
  -webkit-animation: Preloader_load2__3kxyz 1.33s infinite ease 1s;
          animation: Preloader_load2__3kxyz 1.33s infinite ease 1s;
}
.Preloader_Loader__1OSik:after {
  width: 5.2em;
  height: 10.2em;
  background: white;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
          transform-origin: 0px 5.1em;
  -webkit-animation: Preloader_load2__3kxyz 1.33s infinite ease;
          animation: Preloader_load2__3kxyz 1.33s infinite ease;
}
.Preloader_Inverse__3arXP {
  color:white;
}
.Preloader_Dark__-8rzp:before, .Preloader_Dark__-8rzp:after{
  background: black;
}
.Preloader_Inverse__3arXP:before, .Preloader_Inverse__3arXP:after{
  background: #8dbd40;
}
@-webkit-keyframes Preloader_load2__3kxyz {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Preloader_load2__3kxyz {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.src-components-AntMenu-AntMenu-module__container--2jSUy {
  position: relative;
  top: -60px;
}
.src-components-AntMenu-AntMenu-module__container--2jSUy .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  overflow-y: auto;
  overflow-x: hidden;
}
.src-components-AntMenu-AntMenu-module__container--2jSUy .ant-menu-submenu-title,
.src-components-AntMenu-AntMenu-module__container--2jSUy .ant-menu-item {
  text-align: left;
  padding-left: 60px;
}
.src-components-AntMenu-AntMenu-module__container--2jSUy .ant-menu-inline .ant-menu-item,
.src-components-AntMenu-AntMenu-module__container--2jSUy .ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.src-components-AntMenu-AntMenu-module__imgContainer--1ET5b {
  height: 60px;
}
.src-components-AntMenu-AntMenu-module__imgContainer--1ET5b .ant-btn {
  left: 18px;
}
.src-components-AntMenu-AntMenu-module__Logo--1hxpG {
  height: 60px;
  width: 232px;
  padding: 13px;
  top: 5px;
  position: relative;
}
.src-components-AntMenu-AntMenu-module__AltLogo--GH2He {
  position: relative;
  left: 45px;
  height: 50px;
  padding: 10px;
  display: none;
}
.src-components-AntMenu-AntMenu-module__addApp--2soku {
  font-weight: 500;
}
.src-components-AntMenu-AntMenu-module__drawerMenu--2yzfO {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;
}
.src-components-AntMenu-AntMenu-module__drawerMenu--2yzfO ul {
  height: 100%;
}
.src-components-AntMenu-AntMenu-module__drawerLogo--26f5s {
  width: 170px;
  display: block;
}
.src-components-AntMenu-AntMenu-module__menuIcon--3bq1P {
  position: fixed;
  width: 36px;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  left: 7px;
  top: 7px;
  font-size: 30px;
  visibility: hidden;
  opacity: 0;
}
.src-components-AntMenu-AntMenu-module__menuIcon--3bq1P > * {
  color: white;
}
.src-components-AntMenu-AntMenu-module__sideMenu--Ko4A7 > ul {
  height: calc(100vh - 50px);
}
@media (max-width: 800px) {
  .src-components-AntMenu-AntMenu-module__sideMenu--Ko4A7 {
    display: none;
  }
  .src-components-AntMenu-AntMenu-module__menuIcon--3bq1P {
    visibility: visible;
    opacity: 1;
  }
  .src-components-AntMenu-AntMenu-module__container--2jSUy {
    width: 100vw;
    overflow: auto;
    position: fixed;
    left: 0px;
    top: 60px;
    z-index: 1000;
  }
  .src-components-AntMenu-AntMenu-module__imgContainer--1ET5b {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
  }
  .src-components-AntMenu-AntMenu-module__Logo--1hxpG {
    display: none;
  }
  .src-components-AntMenu-AntMenu-module__AltLogo--GH2He {
    display: block;
  }
}

.src-components-Content-Content-module__Container--GquK2 {
  display: flex;
  height: calc(100vh - 50px);
}
.src-components-Content-Content-module__content--2nMiU {
  display: block;
  overflow: auto;
  text-align: center;
  padding: 20px 28px 0px;
  height: 100%;
  width: 100%;
  position: relative;
  background: #f5f5f5;
}
.src-components-Content-Content-module__children--2t818 {
  padding: 50px calc(50% - 600px) 40px;
  height: 100%;
  overflow: auto;
}
.src-components-Content-Content-module__children--2t818 > div {
  overflow: hidden;
}
.src-components-Content-Content-module__LargeSize--1f-_S {
  width: calc(100% - 90px);
}
.src-components-Content-Content-module__TopMenu--2g035 {
  height: 50px;
  width: 100%;
  background: #8dbe40;
}
.src-components-Content-Content-module__Logo--3wPid {
  height: 60px;
  padding: 13px;
  display: block;
}
.src-components-Content-Content-module__AltLogo--2osno {
  height: 59px;
  padding: 13px;
  display: none;
}
.src-components-Content-Content-module__Chat--9MdEt {
  position: absolute;
  top: 20px;
  right: 150px;
}
.src-components-Content-Content-module__Settings--5vWuG {
  position: absolute;
  top: 20px;
  right: 70px;
}
.src-components-Content-Content-module__Call--1FROG {
  position: absolute;
  top: 20px;
  right: 120px;
}
.src-components-Content-Content-module__AppSelect--1dGuU {
  position: relative;
  top: 15px;
  margin-right: 70px;
  float: right;
}
.src-components-Content-Content-module__AddApp--w_FQm {
  position: absolute;
  top: 20px;
  right: 120px;
}
.src-components-Content-Content-module__LogOut--2RMNy {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 200;
}
.src-components-Content-Content-module__title--5ogec {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 5px 5px 5px 8px;
  align-items: center;
  z-index: 200;
  width: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  border-bottom: 1px solid #e8e8e8;
}
.src-components-Content-Content-module__title--5ogec h1 {
  margin: 0px;
  font-size: 20px;
  color: #8dbe3f !important;
  font-weight: 700;
  text-align: left;
  display: none;
}
.src-components-Content-Content-module__title--5ogec .src-components-Content-Content-module__regularTitle--9k0yL {
  display: block;
}
.src-components-Content-Content-module__responsiveTitle--eftpO {
  display: none;
  color: #8dbe3f !important;
}
.src-components-Content-Content-module__hasTabs--1_stT {
  padding-top: 60px;
}
.src-components-Content-Content-module__FullSize--3kdXP .src-components-Content-Content-module__content--2nMiU {
  padding: 0px 0px 0px;
}
.src-components-Content-Content-module__FullSize--3kdXP .src-components-Content-Content-module__children--2t818 {
  padding: 50px 10px 10px;
}
@media (max-width: 800px) {
  .src-components-Content-Content-module__TopMenu--2g035 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 999;
  }
  .src-components-Content-Content-module__Container--GquK2 {
    top: 50px;
    height: calc(100% - 50px);
    position: relative;
  }
  .src-components-Content-Content-module__content--2nMiU {
    width: 100%;
    padding: 12px;
    height: calc(100vh - 50px);
  }
  .src-components-Content-Content-module__AppSelect--1dGuU {
    top: 56px;
    background: #8dbe3f;
    position: fixed;
    width: 100vw;
    height: 43px;
  }
  .src-components-Content-Content-module__AppSelect--1dGuU > * {
    width: calc(100% - 20px) !important;
    top: 2px;
  }
  .src-components-Content-Content-module__title--5ogec h1 {
    display: block;
  }
  .src-components-Content-Content-module__regularTitle--9k0yL {
    display: none !important;
  }
  .src-components-Content-Content-module__responsiveTitle--eftpO {
    display: block;
  }
  .src-components-Content-Content-module__Logo--3wPid {
    display: none;
  }
  .src-components-Content-Content-module__AltLogo--2osno {
    display: block;
  }
}

.ConfirmationDialog_Title__17QdK{
	margin-bottom: 30px;
	text-align: center;
}
.ConfirmationDialog_ButtonContainer__1r71H{
	display: flex;
	justify-content: space-around;
}
.Button_Button__1-aMf{
	background: #8dbf40;
    color: white;
    font-size: 18px;
    height: 50px;
    width: 100px;
    border: none;
    box-shadow: 0px 3px 20px 0px #67676733;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 700;
    outline: none;
    cursor: pointer;
}
.Button_Button__1-aMf:not(.Button_Loading__2vg9D):hover{
	background: #7da939;
}
.Button_White__YSQG4{
    background: white;
    color: #8dbf40;
    border:1px solid #efefef;
}
.Button_White__YSQG4:hover{
    background: whitesmoke !important;
}
.Button_Ghost__RuRNg{
    background: #ffffff00;
    border: 2px solid #8dbf40 !important;
    border-radius: 3px;
    color: #8dbf40;
    box-shadow: none;
}
.Button_Ghost__RuRNg:hover{
    background: #8dbf40 !important;
    color: white;
}
.Button_Payment__NJbhK{
    width: 245px;
    height: 35px;
    border-radius: 4px;
    box-shadow: none;
    background: gray;
    font-size: 14px;
    -webkit-transition: all 0s;
    transition: all 0s;
}
.Button_Payment__NJbhK:hover{
    background: #9f9f9f !important;
}
.Button_Plain__l-RDm{
    border: none !important;
    height: auto !important;
    width: auto !important;
    color: gray;
    background: none !important;
    box-shadow: none !important;
    margin: 10px auto;
    text-decoration: underline;
    font-weight: 500;
}
.BigLoader_Container__2jZAi{
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	background: white;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999999;
}
.BigLoader_Container__2jZAi > div{
	width: 200px;
	text-align: center;
}
.BigLoader_Displayed__1IaT2{
	opacity: 1;
	visibility: visible;
}
.BigLoader_Container__2jZAi img{
	display: block;
	-webkit-filter: brightness(3);
	        filter: brightness(3);
	position: relative;
}
.BigLoader_Container__2jZAi h4{
	text-align: center;
	width: 100%;
	left: 0;
	color: #7f7f7f;
	top: calc(50% + 10px);
}
.BigLoader_Preloader__3yZvz{
	padding: 10px;
	position: relative;
}
.src-components-UI-StopFakeSession-StopFakeSession-module__container--1cQH8 .ant-card {
  position: fixed;
  background: white;
  z-index: 200000;
  left: 12px;
  bottom: 10px;
  box-shadow: 0px 0px 10px 0px #00000022;
  cursor: -webkit-grab;
  cursor: grab;
}
.src-components-UI-StopFakeSession-StopFakeSession-module__container--1cQH8 .ant-card-body {
  padding: 12px 16px;
}

.src-components-UI-AppSelect-AppSelect-module__SelectImg--1kcJG {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  top: -1px;
  position: relative;
}
.src-components-UI-AppSelect-AppSelect-module__select--12IfN {
  width: 300px;
}
@media (max-width: 800px) {
  .src-components-UI-AppSelect-AppSelect-module__select--12IfN {
    width: 100%;
  }
}

.src-components-Distribution-Reviews-Reviews-module__Container--4KCNO {
  padding: 20px 40px;
}
.src-components-Distribution-Reviews-Reviews-module__NoResults--5X_Ji {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}
.src-components-Distribution-Reviews-Reviews-module__NoResults--5X_Ji b {
  color: #c9c9c9;
  font-weight: 400;
}
.src-components-Distribution-Reviews-Reviews-module__NoResults--5X_Ji > span {
  display: grid;
  grid-template: repeat(100%, 2fr);
  grid-gap: 20px;
  align-items: center;
  text-align: center;
}
.src-components-Distribution-Reviews-Reviews-module__StoresTitle--2LkSS {
  text-align: center;
  margin: 40px 30px 0;
  color: #8dbe40;
  border-bottom: 1px solid #8cbf35;
  padding-bottom: 10px;
}
.src-components-Distribution-Reviews-Reviews-module__Container--4KCNO p {
  text-align: left;
  margin-left: 15px;
}
.src-components-Distribution-Reviews-Reviews-module__Button--27mGe {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.src-components-Distribution-Reviews-Reviews-module__Buttons--_L22c {
  display: block;
  text-align: left;
}
.src-components-Distribution-Reviews-Reviews-module__Buttons--_L22c > * {
  display: inline-block;
  padding: 0 20px 20px 0;
}
.src-components-Distribution-Reviews-Reviews-module__Information--qeHLB {
  display: block !important;
  margin: 20px 40px 30px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 10px 0px #80808038;
  padding-bottom: 20px;
  margin: 20px 0;
}
.src-components-Distribution-Reviews-Reviews-module__MainDescription--2AWUY {
  text-align: justify;
  width: calc(100% - 40px);
  display: block;
  margin: 20px 20px 0px;
}
@media (max-width: 500px) {
  .src-components-Distribution-Reviews-Reviews-module__Container--4KCNO {
    padding: 0px 10px;
  }
}

.src-components-PaymentsPlatform-PaymentsPlatform-module__largeFlex--rZJgw {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__verticalFlex--p0zeG {
  flex-direction: column;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__verticalFlex--p0zeG div {
  width: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__tableContainer--28i-P .ant-typography.ant-typography-secondary {
  text-align: left;
  font-size: 17px;
  margin-top: 10px;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__tableContainer--28i-P .ant-table-title,
.src-components-PaymentsPlatform-PaymentsPlatform-module__tableContainer--28i-P .ant-list-header {
  text-align: left;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__tableContainer--28i-P > div {
  margin: 20px 0px 0px;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__alignRight--1R6Iq {
  text-align: right !important;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__alignCenter--3IimN {
  text-align: center !important;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__Buttons--1rUEt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__Buttons--1rUEt > div {
  padding: 0 20px 0 0;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__DetailsTitle--3Emvj {
  color: #8dbe40;
  text-align: left;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__RefundButton--1LJB1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__linkUser--3Ye1e {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__ButtonsAccount--1h9ig {
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__alert--2IQFr * {
  text-align: left;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__alert--2IQFr a {
  font-weight: bold;
}
.src-components-PaymentsPlatform-PaymentsPlatform-module__alert--2IQFr p {
  margin: 0;
}

.src-components-UI-Scrollable-Scrolable-module__container--68wfo .src-components-UI-Scrollable-Scrolable-module__content--2vvn7 {
  width: 100%;
  overflow-x: scroll;
}
.src-components-UI-Scrollable-Scrolable-module__container--68wfo .src-components-UI-Scrollable-Scrolable-module__content--2vvn7 ::-webkit-scrollbar {
  height: 9px;
  display: inline;
  display: initial;
}
.src-components-UI-Scrollable-Scrolable-module__container--68wfo .src-components-UI-Scrollable-Scrolable-module__content--2vvn7 ::-webkit-scrollbar-track {
  background-color: whitesmoke;
  border: 1px solid gainsboro;
}
.src-components-UI-Scrollable-Scrolable-module__container--68wfo .src-components-UI-Scrollable-Scrolable-module__content--2vvn7 ::-webkit-scrollbar-thumb {
  background-color: lightgray;
  outline: none;
}
.src-components-UI-Scrollable-Scrolable-module__pagination--u9yNd {
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.src-components-PaymentsPlatform-Summary-Summary-module__tableContainer--6nbKf > div {
  margin: 20px auto 0px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__tableContainer--6nbKf > div .ant-typography.ant-typography-secondary {
  text-align: left;
  font-size: 17px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__renewalsTitle--12123 {
  margin-bottom: 30px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__rangePicker--2NQpL {
  margin-top: 20px !important;
}
.src-components-PaymentsPlatform-Summary-Summary-module__Charts--1vxrE {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.src-components-PaymentsPlatform-Summary-Summary-module__gridTwo--34ebG {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 20px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm {
  display: block;
  width: 100%;
  margin-top: -20px;
  border: 1px solid gainsboro;
  border-radius: 4px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  background: aliceblue;
  width: 100%;
  border-radius: 4px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody tr {
  display: flex;
  flex-direction: column;
}
.src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody tr td:first-child {
  font-size: 12px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody tr td:not(:first-child) {
  font-size: 28px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody tr td:not(:first-child) span {
  font-size: 14px;
}
.src-components-PaymentsPlatform-Summary-Summary-module__red--28xY4 {
  color: #f55050;
}
.src-components-PaymentsPlatform-Summary-Summary-module__red--28xY4::before {
  content: "-";
}
.src-components-PaymentsPlatform-Summary-Summary-module__green--ZE5wU {
  color: #2db72d;
}
.src-components-PaymentsPlatform-Summary-Summary-module__green--ZE5wU:before {
  content: "+";
}
@media (max-width: 1270px) {
  .src-components-PaymentsPlatform-Summary-Summary-module__Charts--1vxrE {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1100px) {
  .src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody tr {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .src-components-PaymentsPlatform-Summary-Summary-module__gridTwo--34ebG {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;
  }
  .src-components-PaymentsPlatform-Summary-Summary-module__gridTwo--34ebG > * {
    display: block;
    margin-bottom: 20px !important;
  }
}
@media (max-width: 576px) {
  .src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm {
    margin-top: 0px;
  }
}
@media (max-width: 440px) {
  .src-components-PaymentsPlatform-Summary-Summary-module__summaryTable--mfsYm tbody {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.src-components-PaymentsPlatform-Summary-CardOverview-CardOverview-module__container--3uWDs .ant-statistic-content-prefix,
.src-components-PaymentsPlatform-Summary-CardOverview-CardOverview-module__container--3uWDs .ant-statistic-content-value,
.src-components-PaymentsPlatform-Summary-CardOverview-CardOverview-module__container--3uWDs .ant-statistic-content-value-decimal,
.src-components-PaymentsPlatform-Summary-CardOverview-CardOverview-module__container--3uWDs .ant-statistic-content-suffix {
  font-size: 14px !important;
}

.src-components-PaymentsPlatform-Summary-AutoRenewalsStats-AutoRenewalsStats-module__container--1qJo5 .ant-statistic-content-prefix,
.src-components-PaymentsPlatform-Summary-AutoRenewalsStats-AutoRenewalsStats-module__container--1qJo5 .ant-statistic-content-value,
.src-components-PaymentsPlatform-Summary-AutoRenewalsStats-AutoRenewalsStats-module__container--1qJo5 .ant-statistic-content-value-decimal,
.src-components-PaymentsPlatform-Summary-AutoRenewalsStats-AutoRenewalsStats-module__container--1qJo5 .ant-statistic-content-suffix {
  font-size: 14px !important;
}

.src-components-Distribution-DetailedHistory-DetailedHistory-module__Timeline--2x5nM {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.src-components-Distribution-DetailedHistory-DetailedHistory-module__Timeline--2x5nM p {
  margin: 0 0 5px;
}
.src-components-Distribution-DetailedHistory-DetailedHistory-module__Timeline--2x5nM p b {
  font-weight: 600;
}
.src-components-Distribution-DetailedHistory-DetailedHistory-module__Timeline--2x5nM p.src-components-Distribution-DetailedHistory-DetailedHistory-module__date--2iTCS {
  font-size: 11px;
  background: #eeeeee;
  display: inline-block;
  border-radius: 3px;
  font-weight: 600;
  padding: 1px 5px;
  margin: 0;
}
.src-components-Distribution-DetailedHistory-DetailedHistory-module__Title--2TCC7 {
  text-align: center;
  margin: 40px 30px 0;
  color: #8dbe40;
  padding: 20px;
  margin: 0;
}
.src-components-Distribution-DetailedHistory-DetailedHistory-module__Info--1KE8D {
  color: #8dbe40;
  padding: 20px;
  margin: 0;
  font-size: 0.8em;
}
.src-components-Distribution-DetailedHistory-DetailedHistory-module__Button--35lmq {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.src-components-Distribution-DetailedHistory-DetailedHistory-module__Button--35lmq button.ant-btn {
  margin: 20px 0px;
}

.AnalyticsHome_Container__2XW7x {
}
.AnalyticsHome_Card__2gd8t {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
.AnalyticsHome_Buttons__qxkhS {
}

.AnalyticsHome_subtitle__1AGQo {
  margin-top: 20px;
}

.src-components-Analytics-UncontractedAnalyticsMessage-UncontractedAnalyticsMessage-module__alert--2D8id {
  padding-bottom: 20px;
}
.src-components-Analytics-UncontractedAnalyticsMessage-UncontractedAnalyticsMessage-module__alert--2D8id * {
  text-align: left;
}
.src-components-Analytics-UncontractedAnalyticsMessage-UncontractedAnalyticsMessage-module__alert--2D8id a {
  font-weight: bold;
}
.src-components-Analytics-UncontractedAnalyticsMessage-UncontractedAnalyticsMessage-module__alert--2D8id p {
  margin: 0;
}


.src-components-PushNotifications-NewPushNotification-NewPushNotification-module__container--36EyR .src-components-PushNotifications-NewPushNotification-NewPushNotification-module__content--1ZzCs {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: calc(100% - 336px) 320px;
}
.src-components-PushNotifications-NewPushNotification-NewPushNotification-module__container--36EyR .src-components-PushNotifications-NewPushNotification-NewPushNotification-module__send--2pcLi {
  display: flex;
  margin-top: -20px;
  justify-content: flex-end;
}

.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA {
  position: relative;
  min-height: 400px;
  overflow: hidden;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA > img {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: 0% 0%;
  z-index: 20;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__datetime--kfnxr {
  z-index: 40;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__datetime--kfnxr .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__time--5McEb {
  font-size: 50px;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__datetime--kfnxr .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__date--1H79z {
  margin-top: -10px;
  font-size: 20px;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__datetime--kfnxr * {
  color: white;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__content--1AgTi {
  z-index: 40;
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  border-radius: 2px;
  top: 200px;
  background: white;
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__content--1AgTi > div {
  width: calc(100% - 40px);
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__content--1AgTi * {
  z-index: 50;
  position: relative;
  margin: 0px;
  text-align: left;
  padding: 0;
  width: auto;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__content--1AgTi label {
  font-size: 10px;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__content--1AgTi h4 {
  font-size: 14px;
  padding-right: 8px;
  font-weight: 700;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__content--1AgTi p {
  font-size: 12px;
  padding-right: 8px;
  font-weight: 500 !important;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA .src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__content--1AgTi img {
  width: 40px;
}
.src-components-PushNotifications-PushNotificationDemo-PushNotificationDemo-module__container--1rerA::after {
  content: "";
  display: block;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(#f5f5f5), to(#f5f5f5));
  background: -webkit-linear-gradient(transparent, #f5f5f5, #f5f5f5);
  background: linear-gradient(transparent, #f5f5f5, #f5f5f5);
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 40;
}


.src-components-PushNotifications-PushNotificationsDetails-PushDetails-module__container--2IPW8 .src-components-PushNotifications-PushNotificationsDetails-PushDetails-module__content--1Sv5T {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: calc(100% - 336px) 320px;
}
.src-components-PushNotifications-PushNotificationsDetails-PushDetails-module__container--2IPW8 .src-components-PushNotifications-PushNotificationsDetails-PushDetails-module__send--apsOX {
  display: flex;
  margin-top: -20px;
  justify-content: flex-end;
}
.src-components-PushNotifications-PushNotificationsDetails-PushDetails-module__buttons--21zq- {
  display: flex;
  margin-top: -20px;
  justify-content: space-between;
}
.src-components-PushNotifications-PushNotificationsDetails-PushDetails-module__stats--3hqjy > * {
  margin-bottom: 8px;
}
.src-components-PushNotifications-PushNotificationsDetails-PushDetails-module__stats--3hqjy b {
  font-weight: 700;
}

.src-components-Distribution-Downloads-Downloads-module__flex--2KpZL {
  display: flex;
  justify-content: space-between;
  width: 470px;
}
.src-components-Distribution-Downloads-Downloads-module__filters--22zzr {
  padding-top: 16px;
}
.src-components-Distribution-Downloads-Downloads-module__chart--31iFh {
  padding-right: 24px;
  position: relative;
  padding: 0;
}
.src-components-Distribution-Downloads-Downloads-module__center--2qHWU {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
  padding: 0px;
}
.src-components-Distribution-Downloads-Downloads-module__NoResults--125Yo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}
.src-components-Distribution-Downloads-Downloads-module__firstRow--1Pv5x {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}
.src-components-Distribution-Downloads-Downloads-module__firstRow--1Pv5x .ant-calendar-picker {
  width: calc(100% - 350px);
}
.src-components-Distribution-Downloads-Downloads-module__secondRow--TMR9Q {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.src-components-Distribution-Downloads-Downloads-module__secondRow--TMR9Q .ant-select {
  width: calc(100% - 553px);
}
.src-components-Distribution-Downloads-Downloads-module__storesFilter--3tFQp {
  position: relative;
}
.src-components-Distribution-Downloads-Downloads-module__storesFilter--3tFQp > label {
  position: absolute;
  left: 0px;
  top: -17px;
  font-size: 12px;
  font-weight: 700;
  color: #66a600;
}
.src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR {
  display: block;
  width: 100%;
  margin: 10px 0px;
  border: 1px solid gainsboro;
}
.src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  background: aliceblue;
  width: 100%;
  border-radius: 4px;
}
.src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody tr {
  display: flex;
  flex-direction: column;
}
.src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody tr td:first-child {
  font-size: 12px;
}
.src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody tr td:not(:first-child) {
  font-size: 28px;
}
.src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody tr td:not(:first-child) span {
  font-size: 14px;
}
.src-components-Distribution-Downloads-Downloads-module__alert--3tCW8 {
  margin: 10px 0px;
  padding: 0;
}
.src-components-Distribution-Downloads-Downloads-module__alert--3tCW8 * {
  text-align: left;
}
.src-components-Distribution-Downloads-Downloads-module__alert--3tCW8 a {
  font-weight: bold;
}
.src-components-Distribution-Downloads-Downloads-module__alert--3tCW8 b {
  font-weight: 500;
}
.src-components-Distribution-Downloads-Downloads-module__alert--3tCW8 p,
.src-components-Distribution-Downloads-Downloads-module__alert--3tCW8 b {
  margin: 0 !important;
}
@media (max-width: 1100px) {
  .src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody tr {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .src-components-Distribution-Downloads-Downloads-module__filters--22zzr > div:nth-child(3) {
    padding-top: 24px;
  }
  .src-components-Distribution-Downloads-Downloads-module__filters--22zzr > div:nth-child(3) label {
    display: block;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .src-components-Distribution-Downloads-Downloads-module__filters--22zzr > div:nth-child(2) {
    padding-top: 8px !important;
  }
}
@media (max-width: 460px) {
  .src-components-Distribution-Downloads-Downloads-module__summaryTable--2_OVR tbody {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__Container--oMVA4 {
  display: block;
  position: relative;
  border: 1px solid #edeeef;
  border-radius: 3px;
  margin: 10px 0px;
  padding: 15px 15px 20px;
  text-align: left;
  box-shadow: 0px 1px 8px 0px #dcdcdc9f;
  background-color: white;
}
.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__Container--oMVA4 button {
  position: absolute;
  right: 12px;
  top: 12px;
}
.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__Header--1axg8 {
  display: flex;
  justify-content: space-between;
}
.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__Title--3_rEe {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__Info--gYolX {
  margin-top: 8px;
  padding-top: 9px;
  border-top: 1px solid #e8e8e8;
}
.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__InfoText--2uMMr {
  display: flex;
}
.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__InfoText--2uMMr > p {
  margin-right: 24px;
}
.src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__total--aJ16r {
  position: absolute;
  top: 55px;
  font-size: 30px;
  line-height: 38px;
  white-space: nowrap;
}
@media (max-width: 640px) {
  .src-components-Distribution-Downloads-DownloadsCard-DownloadsCard-module__Container--oMVA4 {
    padding-bottom: 64px;
  }
}

@media (max-width: 576px) {
  .src-components-WelcomePage-WelcomePage-module__marginBottom--EHv0P {
    margin-bottom: 16px;
  }
}

.src-components-UI-StatisticCard-StatisticCard-module__Container--3pFqp {
  display: block;
  position: relative;
  height: 255px;
  border: 1px solid #edeeef;
  border-radius: 3px;
  padding: 15px 15px 10px;
  text-align: left;
  box-shadow: 0px 1px 8px 0px #dcdcdc9f;
  background: white;
}
.src-components-UI-StatisticCard-StatisticCard-module__Header--3Qs8L {
  display: flex;
  justify-content: space-between;
}
.src-components-UI-StatisticCard-StatisticCard-module__Title--ZlcHg {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.src-components-UI-StatisticCard-StatisticCard-module__Chart--37SGK {
  bottom: -70px;
  position: relative;
  height: 140px;
}
.src-components-UI-StatisticCard-StatisticCard-module__Info--37Rkt {
  margin-top: 8px;
  padding-top: 9px;
  border-top: 1px solid #e8e8e8;
}
.src-components-UI-StatisticCard-StatisticCard-module__InfoText--Dtw1t {
  display: flex;
}
.src-components-UI-StatisticCard-StatisticCard-module__InfoText--Dtw1t > p {
  margin-right: 24px;
}
.src-components-UI-StatisticCard-StatisticCard-module__total--1pbTz {
  position: absolute;
  top: 55px;
  font-size: 30px;
  line-height: 38px;
  white-space: nowrap;
}

.src-components-UI-AppDistributionCard-AppDistributionCard-module__Container--1M-Dt {
  display: block;
  position: relative;
  border: 1px solid #edeeef;
  border-radius: 3px;
  padding: 15px 15px 16px;
  text-align: left;
  box-shadow: 0px 1px 8px 0px #dcdcdc9f;
  background: white;
  height: 600px;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__Header--3bRul {
  display: flex;
  justify-content: space-between;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__Title--2o9_Q {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__Forms--mweG3 {
  color: rgba(0, 0, 0, 0.45);
  padding-top: 10px;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__Forms--mweG3 .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__Forms--mweG3 .ant-checkbox-group-item {
  margin: 10px 0px;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__buttonForms--_32_P {
  display: flex;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__Chart--1idpL {
  bottom: -40px;
  position: relative;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__InfoText--1p7_8 {
  margin-top: 8px;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__InfoText--1p7_8 > p {
  margin-right: 24px;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__InfoText--1p7_8 > p > b {
  font-weight: 600;
}
.src-components-UI-AppDistributionCard-AppDistributionCard-module__total--2nfn7 {
  position: absolute;
  top: 5px;
  font-size: 30px;
}

.src-components-WelcomePage-Bulletin-Bulletin-module__Container--2ON5C {
  display: block;
  position: relative;
  min-height: 600px;
  border: 1px solid #edeeef;
  border-radius: 3px;
  padding: 15px 15px 10px;
  text-align: left;
  box-shadow: 0px 1px 8px 0px #dcdcdc9f;
  background: white;
}
.src-components-WelcomePage-Bulletin-Bulletin-module__List--3cHVT {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.src-components-WelcomePage-Bulletin-Bulletin-module__List--3cHVT p {
  margin: 0 !important;
}
.src-components-WelcomePage-Bulletin-Bulletin-module__List--3cHVT p span {
  background: #ddecff;
  padding: 1px 4px;
  font-size: 10px;
  border-radius: 4px;
  font-weight: 600;
  display: block;
  width: 80px;
  text-align: center;
}
.src-components-WelcomePage-Bulletin-Bulletin-module__content--1TFCX img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV p,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h1,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h2,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h3,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h4,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h5 {
  margin-bottom: 15px !important;
}
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV p b,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h1 b,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h2 b,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h3 b,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h4 b,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h5 b,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV p strong,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h1 strong,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h2 strong,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h3 strong,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h4 strong,
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV h5 strong {
  font-weight: bold !important;
}
.src-components-WelcomePage-Bulletin-Bulletin-module__modal--1g9GV img {
  max-width: 100% !important;
}
@media (max-width: 1200px) {
  .src-components-WelcomePage-Bulletin-Bulletin-module__Container--2ON5C {
    height: 360px;
    margin-bottom: 16px;
  }
}

.src-components-AppPromotion-Summary-Summary-module__container--i2g_B .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-Summary-Summary-module__container--i2g_B .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}
@media (max-width: 500px) {
  .src-components-AppPromotion-Summary-Summary-module__container--i2g_B .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0px !important;
  }
}

@media (max-width: 600px) {
  .src-components-AppPromotion-Summary-SummaryCard-SummaryCard-module__container--SkQkO .ant-card-grid.ant-card-grid-hoverable {
    padding: 10px !important;
  }
  .src-components-AppPromotion-Summary-SummaryCard-SummaryCard-module__container--SkQkO .ant-statistic-title {
    font-size: 10px;
  }
  .src-components-AppPromotion-Summary-SummaryCard-SummaryCard-module__container--SkQkO .ant-statistic-content {
    font-size: 16px;
  }
}
@media (max-width: 350px) {
  .src-components-AppPromotion-Summary-SummaryCard-SummaryCard-module__container--SkQkO .ant-card-grid.ant-card-grid-hoverable {
    padding: 10px !important;
  }
  .src-components-AppPromotion-Summary-SummaryCard-SummaryCard-module__container--SkQkO .ant-statistic-title {
    font-size: 7px !important;
  }
  .src-components-AppPromotion-Summary-SummaryCard-SummaryCard-module__container--SkQkO .ant-statistic-content {
    font-size: 10px !important;
  }
}

.src-components-AppPromotion-UncontractedAppPromotion-UncontractedAnalyticsMessage-module__alert--283i- {
  padding-bottom: 20px;
}
.src-components-AppPromotion-UncontractedAppPromotion-UncontractedAnalyticsMessage-module__alert--283i- * {
  text-align: left;
}
.src-components-AppPromotion-UncontractedAppPromotion-UncontractedAnalyticsMessage-module__alert--283i- a {
  font-weight: bold;
}
.src-components-AppPromotion-UncontractedAppPromotion-UncontractedAnalyticsMessage-module__alert--283i- p {
  margin: 0;
}

.src-components-AppPromotion-DataSegmentation-DataPerStore-DataPerStore-module__container--1z0zX .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-DataSegmentation-DataPerStore-DataPerStore-module__container--1z0zX .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}

.src-components-AppPromotion-DataSegmentation-TrendPerStore-TrendPerStore-module__container--1-uVv .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-DataSegmentation-TrendPerStore-TrendPerStore-module__container--1-uVv .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}
@media (max-width: 500px) {
  .src-components-AppPromotion-DataSegmentation-TrendPerStore-TrendPerStore-module__container--1-uVv .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0px !important;
  }
}

.src-components-AppPromotion-UserActivity-UsersPerDay-UsersPerDay-module__container--1JLJt .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-UserActivity-UsersPerDay-UsersPerDay-module__container--1JLJt .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}
@media (max-width: 500px) {
  .src-components-AppPromotion-UserActivity-UsersPerDay-UsersPerDay-module__container--1JLJt .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0px !important;
  }
}

.src-components-AppPromotion-UserActivity-UsersSource-UsersSource-module__container--1SkkM .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-UserActivity-UsersSource-UsersSource-module__container--1SkkM .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}

.src-components-AppPromotion-UsersRetention-UsersRetention-module__container--1Z9MT .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-UsersRetention-UsersRetention-module__container--1Z9MT .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}

.src-components-AppPromotion-PromotionExpenses-PromotionExpenses-module__container--1-rtL .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-PromotionExpenses-PromotionExpenses-module__container--1-rtL .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}

@media (max-width: 600px) {
  .src-components-AppPromotion-PromotionExpenses-AmountSpent-AmountSpent-module__container--pxSYr .ant-card-grid.ant-card-grid-hoverable {
    padding: 10px !important;
  }
  .src-components-AppPromotion-PromotionExpenses-AmountSpent-AmountSpent-module__container--pxSYr .ant-statistic-title {
    font-size: 10px;
  }
  .src-components-AppPromotion-PromotionExpenses-AmountSpent-AmountSpent-module__container--pxSYr .ant-statistic-content {
    font-size: 16px;
  }
}
@media (max-width: 350px) {
  .src-components-AppPromotion-PromotionExpenses-AmountSpent-AmountSpent-module__container--pxSYr .ant-card-grid.ant-card-grid-hoverable {
    padding: 10px !important;
  }
  .src-components-AppPromotion-PromotionExpenses-AmountSpent-AmountSpent-module__container--pxSYr .ant-statistic-title {
    font-size: 7px !important;
  }
  .src-components-AppPromotion-PromotionExpenses-AmountSpent-AmountSpent-module__container--pxSYr .ant-statistic-content {
    font-size: 10px !important;
  }
}

.src-components-AppPromotion-UserActivity-UserBehavior-module__container--3k2OF .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-UserActivity-UserBehavior-module__container--3k2OF .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}
@media (max-width: 500px) {
  .src-components-AppPromotion-UserActivity-UserBehavior-module__container--3k2OF .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0px !important;
  }
}

.src-components-AppPromotion-UsersRetention-UsersTable-UsersTable-module__container--3vH0A td {
  padding: 0 !important;
  text-align: center;
}
.src-components-AppPromotion-UsersRetention-UsersTable-UsersTable-module__cells--3DUxp {
  padding: 0px 8px 0px 8px !important;
}

.src-components-AppPromotion-DataSegmentation-TrendPerVersion-TrendPerVersion-module__container--26mCs .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-DataSegmentation-TrendPerVersion-TrendPerVersion-module__container--26mCs .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}
@media (max-width: 500px) {
  .src-components-AppPromotion-DataSegmentation-TrendPerVersion-TrendPerVersion-module__container--26mCs .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0px !important;
  }
}

.src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}
@media (max-width: 600px) {
  .src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-card-grid.ant-card-grid-hoverable {
    padding: 10px !important;
  }
  .src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-statistic-title {
    font-size: 10px;
  }
  .src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-statistic-content {
    font-size: 16px;
  }
}
@media (max-width: 350px) {
  .src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-card-grid.ant-card-grid-hoverable {
    padding: 10px !important;
  }
  .src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-statistic-title {
    font-size: 7px !important;
  }
  .src-components-AppPromotion-OverralData-OverralData-Summary-Summary-module__container--2KCiX .ant-statistic-content {
    font-size: 10px !important;
  }
}

.src-components-AppPromotion-OverralData-OverralData-Chart-Chart-module__container--1H2HR .ant-card-head {
  padding: 0 24px;
  background-color: #fafafa;
}
.src-components-AppPromotion-OverralData-OverralData-Chart-Chart-module__container--1H2HR .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background-color: white;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 27px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}
@media (max-width: 500px) {
  .src-components-AppPromotion-OverralData-OverralData-Chart-Chart-module__container--1H2HR .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0px !important;
  }
}

.src-components-FeedbackBar-FeedbackBar-module__container--1yUOC {
  position: fixed;
  max-width: 320px;
  background: white;
  right: 20px;
  bottom: -500px;
  z-index: 9990;
  box-shadow: 0px 0px 20px 0px #8080808a;
  padding: 30px 22px 0px;
  border-radius: 4px;
  -webkit-transition: bottom 0.8s;
  transition: bottom 0.8s;
}
.src-components-FeedbackBar-FeedbackBar-module__container--1yUOC .ant-form-item-control {
  text-align: center;
}
.src-components-FeedbackBar-FeedbackBar-module__container--1yUOC h4 {
  color: #8dbe3f;
  font-weight: 700;
  font-size: 15px;
}
.src-components-FeedbackBar-FeedbackBar-module__container--1yUOC .src-components-FeedbackBar-FeedbackBar-module__close--2hY8j {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
.src-components-FeedbackBar-FeedbackBar-module__container--1yUOC .src-components-FeedbackBar-FeedbackBar-module__flex--2uzdc {
  padding-bottom: 16px;
}
.src-components-FeedbackBar-FeedbackBar-module__container--1yUOC textarea {
  width: calc(100% - 20px);
}
.src-components-FeedbackBar-FeedbackBar-module__container--1yUOC .src-components-FeedbackBar-FeedbackBar-module__buttonSend--1twFg {
  display: flex;
  justify-content: center;
}
.src-components-FeedbackBar-FeedbackBar-module__open--3kJXw {
  bottom: 20px;
}

.src-components-Settings-Settings-module__container--3zOvZ {
  padding: 0 40px 40px;
}
.src-components-Settings-Settings-module__Avatar--1bdzb {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 32px;
}
.src-components-Settings-Settings-module__roles--3lOLJ {
  text-align: left;
}
.src-components-Settings-Settings-module__roles--3lOLJ b {
  font-weight: 700;
}


.src-components-Distribution-DistributionDetails-AppInChinaBadge-BadgeTrakingDetails-BadgeTrackingDetails-module__container--rK_Ss {
  padding: 24px;
}

.src-components-CloudStore-CloudStore-module__container--3zCAp {
  text-align: left;
}
.src-components-CloudStore-CloudStore-module__container--3zCAp h4 {
  color: #8dbe3f !important;
}
.src-components-CloudStore-CloudStore-module__container--3zCAp .src-components-CloudStore-CloudStore-module__text--19IR4 {
  padding: 0 50px;
}
.src-components-CloudStore-CloudStore-module__container--3zCAp .src-components-CloudStore-CloudStore-module__center--16O4u {
  text-align: center;
  padding-top: 30px;
}
.src-components-CloudStore-CloudStore-module__container--3zCAp .ant-table {
  margin-bottom: 30px;
}
.src-components-CloudStore-CloudStore-module__container--3zCAp .ant-steps-item-icon {
  border-color: #8dbe3f !important;
}
.src-components-CloudStore-CloudStore-module__container--3zCAp .ant-steps-item-icon span {
  color: #97c549 !important;
  font-weight: 700;
}
.src-components-CloudStore-CloudStore-module__container--3zCAp .ant-steps-item-title {
  color: #97c549 !important;
  font-weight: 700;
}
.src-components-CloudStore-CloudStore-module__title--lDd14 {
  color: #8dbe3f !important;
}

.src-components-CloudStore-Summary-CredentialsModal-CredentialsModal-module__title--1B20L {
  display: block;
  width: 100%;
}

.src-components-CloudStore-Summary-AlertRenew-AlertRenew-module__alert--14uuk {
  padding-bottom: 20px;
}
.src-components-CloudStore-Summary-AlertRenew-AlertRenew-module__alert--14uuk * {
  text-align: left;
}
.src-components-CloudStore-Summary-AlertRenew-AlertRenew-module__alert--14uuk a {
  font-weight: bold;
}
.src-components-CloudStore-Summary-AlertRenew-AlertRenew-module__alert--14uuk p {
  margin: 0;
}

.src-components-CloudStore-Shop-ECS-ECS-module__container--3Cuek {
  padding-top: 16px;
}
.src-components-CloudStore-Shop-ECS-ECS-module__container--3Cuek p {
  text-align: left;
}
.src-components-CloudStore-Shop-ECS-ECS-module__subtitle--2L-yZ {
  text-align: center;
  margin: 0px 0px 24px;
}
.src-components-CloudStore-Shop-ECS-ECS-module__card--34apx {
  display: flex;
  flex-direction: column;
  border: 1px solid gainsboro;
}
.src-components-CloudStore-Shop-ECS-ECS-module__card--34apx img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.src-components-CloudStore-Shop-ECS-ECS-module__card--34apx h3 {
  padding: 10px;
}
.src-components-CloudStore-Shop-ECS-ECS-module__card--34apx p {
  padding: 0px 10px 10px;
}

.src-components-CloudStore-Shop-Domains-Domains-module__container--3-Neq {
  padding-top: 16px;
}
.src-components-CloudStore-Shop-Domains-Domains-module__container--3-Neq p {
  text-align: left;
}
.src-components-CloudStore-Shop-Domains-Domains-module__container--3-Neq .ant-form-vertical .ant-form-item {
  padding-bottom: 0px;
  margin: 0px;
}
.src-components-CloudStore-Shop-Domains-Domains-module__search--2wKXm {
  padding: 20px 0px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.src-components-CloudStore-Shop-Domains-Domains-module__search--2wKXm > div:first-child {
  width: calc(100% - 110px);
}
.src-components-CloudStore-Shop-Domains-Domains-module__search--2wKXm > div:nth-child(2),
.src-components-CloudStore-Shop-Domains-Domains-module__search--2wKXm button {
  width: 100px;
}
.src-components-CloudStore-Shop-Domains-Domains-module__search--2wKXm::before,
.src-components-CloudStore-Shop-Domains-Domains-module__search--2wKXm::after {
  display: none !important;
  content: none;
}

.src-components-CloudStore-Shop-Domains-DomainCard-DomainCard-module__result--3FV-a {
  border: 1px solid gainsboro;
  border-radius: 4px;
}
.src-components-CloudStore-Shop-Domains-DomainCard-DomainCard-module__title--pjHFQ {
  margin: 0px;
  text-align: center !important;
}
.src-components-CloudStore-Shop-Domains-DomainCard-DomainCard-module__title--pjHFQ b {
  font-weight: 500;
}
.src-components-CloudStore-Shop-Domains-DomainCard-DomainCard-module__total--qfKDj {
  color: #8dbe3f !important;
  font-weight: 400;
  padding-bottom: 20px;
}

.src-components-CloudStore-Shop-Database-Database-module__container--18dkF {
  padding-top: 16px;
}
.src-components-CloudStore-Shop-Database-Database-module__container--18dkF p {
  text-align: left;
}
.src-components-CloudStore-Shop-Database-Database-module__subtitle--1Ebj- {
  text-align: center;
  margin: 16px 0px 24px;
}
.src-components-CloudStore-Shop-Database-Database-module__card--szOWb {
  display: flex;
  border: 1px solid gainsboro;
  height: 190px;
  margin-bottom: 20px;
}
.src-components-CloudStore-Shop-Database-Database-module__card--szOWb img {
  height: 190px;
  width: 190px;
  object-fit: cover;
  object-position: center;
}
.src-components-CloudStore-Shop-Database-Database-module__card--szOWb div {
  width: calc(100% - 190px);
}
.src-components-CloudStore-Shop-Database-Database-module__card--szOWb h3 {
  padding: 10px;
}
.src-components-CloudStore-Shop-Database-Database-module__card--szOWb p {
  padding: 0px 10px 10px;
}

.src-components-CloudStore-Cart-Cart-module__payment--1h1_F {
  height: 800px;
  text-align: center;
  padding-top: 20px;
}
.src-components-CloudStore-Cart-Cart-module__payment--1h1_F .ant-radio-button-wrapper {
  margin-right: 20px;
  border-radius: 4px !important;
}
.src-components-CloudStore-Cart-Cart-module__payment--1h1_F > p {
  margin-top: 1em;
}
.src-components-CloudStore-Cart-Cart-module__shopButton--3Uqpc {
  text-align: right;
  padding: 16px 0px 32px;
}
.src-components-CloudStore-Cart-Cart-module__description--eAU_Y {
  text-align: left;
}
.src-components-CloudStore-Cart-Cart-module__alertText--1v4xn div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.src-components-CloudStore-Cart-Cart-module__alertText--1v4xn p {
  margin: 0 !important;
  font-size: 24px;
}
.src-components-CloudStore-Cart-Cart-module__alertText--1v4xn b {
  font-weight: 600;
  font-size: 24px;
}
.src-components-CloudStore-Cart-Cart-module__alertInfo--3dSoC div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.src-components-CloudStore-Cart-Cart-module__alertInfo--3dSoC p {
  margin: 0 !important;
  font-size: 15px;
}
.src-components-CloudStore-Cart-Cart-module__alertInfo--3dSoC b {
  font-weight: 600;
  font-size: 15px;
}
.src-components-CloudStore-Cart-Cart-module__paymentMethod--1QuHs {
  font-weight: 400;
  font-size: large;
}

.src-components-CloudStore-Cart-PaypalButton-PayPalButton-module__container--2Y81G .paypal-button {
  width: 200px;
}

.src-components-CloudStore-Cart-AccountDetails-AccountDetails-module__container--2kGd0 p {
  margin-right: 10px;
  font-weight: 600;
}

.src-components-CloudStore-Cart-AccountBalancePay-AccountBalancePay-module__container--3X7bd .ant-alert b {
  font-size: 16px;
  font-weight: 700;
}
.src-components-CloudStore-Cart-AccountBalancePay-AccountBalancePay-module__container--3X7bd .ant-input-number-input {
  text-align: right;
  font-weight: 700;
}
.src-components-CloudStore-Cart-AccountBalancePay-AccountBalancePay-module__container--3X7bd .ant-col.ant-form-item-label {
  height: 40px;
  top: 12px;
  font-weight: 400;
}

.src-components-CloudStore-AccountBalance-AccountBalance-module__container--n4cn- {
  text-align: left;
}

.src-components-CloudStore-ServicesConfiguration-ServicesConfiguration-module__container--Ym9I9 .ant-form {
  margin-top: 16px;
}
.src-components-CloudStore-ServicesConfiguration-ServicesConfiguration-module__container--Ym9I9 .ant-collapse {
  margin-top: 20px;
}
.src-components-CloudStore-ServicesConfiguration-ServicesConfiguration-module__container--Ym9I9 .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.src-components-CloudStore-ServicesConfiguration-ServicesConfiguration-module__container--Ym9I9 .ant-alert {
  margin-top: 20px;
  text-align: left;
}
.src-components-CloudStore-ServicesConfiguration-ServicesConfiguration-module__container--Ym9I9 .ant-form-item-control {
  margin-top: 20px;
}
.src-components-CloudStore-ServicesConfiguration-ServicesConfiguration-module__container--Ym9I9 .ant-select {
  width: 300px;
}
.src-components-CloudStore-ServicesConfiguration-ServicesConfiguration-module__info--3Twv_ {
  font-size: small;
  margin-top: 10px;
}

.src-components-CloudStore-ServicesConfiguration-ECSConfiguration-ECSDetails-ECSDetails-module__container--30QPq {
  padding: 0 20px 20px;
}
.src-components-CloudStore-ServicesConfiguration-ECSConfiguration-ECSDetails-ECSDetails-module__container--30QPq .ant-radio-group {
  margin-bottom: 10px;
}
.src-components-CloudStore-ServicesConfiguration-ECSConfiguration-ECSDetails-ECSDetails-module__container--30QPq .ant-form-item-control {
  margin-top: 0px !important;
}
.src-components-CloudStore-ServicesConfiguration-ECSConfiguration-ECSDetails-ECSDetails-module__title--xf2HW {
  color: #8dbe3f;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
}
.src-components-CloudStore-ServicesConfiguration-ECSConfiguration-ECSDetails-ECSDetails-module__info--2rzI5 {
  font-size: small;
  margin: 0 !important;
  font-weight: 250;
}
.src-components-CloudStore-ServicesConfiguration-ECSConfiguration-ECSDetails-ECSDetails-module__price--2UDx4 {
  margin: 0 !important;
}
.src-components-CloudStore-ServicesConfiguration-ECSConfiguration-ECSDetails-ECSDetails-module__total--2E1iS {
  color: #8dbe3f !important;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.src-components-CloudStore-ServicesConfiguration-DatabaseConfiguration-DatabaseConfiguration-module__form--3IcMq {
  margin-top: 30px !important;
  padding: 24px 24px 12px !important;
  border: 1px solid gainsboro;
  border-radius: 4px;
}
.src-components-CloudStore-ServicesConfiguration-DatabaseConfiguration-DatabaseConfiguration-module__form--3IcMq * {
  text-align: left;
}
.src-components-CloudStore-ServicesConfiguration-DatabaseConfiguration-DatabaseConfiguration-module__button--28pf2 {
  text-align: center;
  padding-top: 20px;
}
.src-components-CloudStore-ServicesConfiguration-DatabaseConfiguration-DatabaseConfiguration-module__total--2-xPh {
  color: #8dbe3f !important;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.src-components-CloudStore-AccountBalance-Deposit-Deposit-module__container--2NYuy .ant-form-item-with-help {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.src-components-CloudStore-AccountBalance-Deposit-Deposit-module__container--2NYuy .ant-input-number-input {
  text-align: center;
}
.src-components-CloudStore-AccountBalance-Deposit-Deposit-module__container--2NYuy .ant-col.ant-form-item-label {
  text-align: center;
  font-weight: 500;
  top: 12px;
  height: 40px;
  padding-right: 195px;
}

.src-components-CloudStore-AccountBalance-Deposit-PayPalButtonDeposit-PayPalButton-module__container--24fal .paypal-button {
  width: 200px;
}

.src-components-BadgeHome-BadgeAnalytics-page-module__container--1mcJY {
  padding: 20px;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__container--1mcJY > div {
  margin-bottom: 20px;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  border: 1px solid gainsboro;
  border-radius: 4px;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  background: aliceblue;
  width: 100%;
  border-radius: 4px;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody tr {
  display: flex;
  flex-direction: column;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody tr td:first-child {
  font-size: 12px;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody tr td:not(:first-child) {
  font-size: 28px;
  text-align: center;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody tr td:not(:first-child) span {
  font-size: 14px;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__filters--2ojd5 {
  display: flex;
  align-items: flex-start;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__filters--2ojd5 > div {
  margin-right: 30px;
}
.src-components-BadgeHome-BadgeAnalytics-page-module__Charts--bGAZ7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1270px) {
  .src-components-BadgeHome-BadgeAnalytics-page-module__Charts--bGAZ7 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 470px) {
  .src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody tr td:first-child {
    font-size: 10px;
  }
  .src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody tr td:not(:first-child) {
    font-size: 18px;
    text-align: center;
  }
  .src-components-BadgeHome-BadgeAnalytics-page-module__summaryTable--1CUpV tbody tr td:not(:first-child) span {
    font-size: 10px;
  }
}

.src-components-BadgeHome-BadgeAnalytics-HeatMap-module__container--35yju {
  overflow-x: scroll;
}

.src-components-BadgeHome-BadgeHome-module__container--1Iq2F {
  padding: 0 40px 40px;
}
.src-components-BadgeHome-BadgeHome-module__Avatar--gn-ol {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 32px;
}
.src-components-BadgeHome-BadgeHome-module__roles--2Hy8j {
  text-align: left;
}
.src-components-BadgeHome-BadgeHome-module__roles--2Hy8j b {
  font-weight: 700;
}

.src-components-BadgeHome-AICBadge-AICBadge-module__container--1zZ4y {
  max-width: 800px;
  margin: auto;
}
.src-components-BadgeHome-AICBadge-AICBadge-module__container--1zZ4y .ant-checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.src-components-BadgeHome-AICBadge-AICBadge-module__container--1zZ4y p {
  margin-left: 2px;
}
.src-components-BadgeHome-AICBadge-AICBadge-module__colorPicker--2p1fS {
  margin: 0px 0px 30px;
}
.src-components-BadgeHome-AICBadge-AICBadge-module__title--2xRi6 {
  color: #97c549;
  font-weight: 400;
}

.src-components-CloudStore-RenewOrder-RenewOrder-module__alertText--Nwsk0 div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.src-components-CloudStore-RenewOrder-RenewOrder-module__alertText--Nwsk0 p {
  margin: 0 !important;
  font-size: 24px;
}
.src-components-CloudStore-RenewOrder-RenewOrder-module__alertText--Nwsk0 b {
  font-weight: 600;
  font-size: 24px;
}
.src-components-CloudStore-RenewOrder-RenewOrder-module__payment--3KdFp {
  margin-top: 20px;
}
.src-components-CloudStore-RenewOrder-RenewOrder-module__options--3xC5U {
  margin-top: 13px;
}

.src-components-CloudStore-RenewOrder-AccountBalanceRenew-AccountBalanceRenew-module__container--25i6a .ant-alert b {
  font-size: 16px;
  font-weight: 700;
}
.src-components-CloudStore-RenewOrder-AccountBalanceRenew-AccountBalanceRenew-module__container--25i6a .ant-input-number-input {
  text-align: right;
  font-weight: 700;
}
.src-components-CloudStore-RenewOrder-AccountBalanceRenew-AccountBalanceRenew-module__container--25i6a .ant-col.ant-form-item-label {
  height: 40px;
  top: 12px;
  font-weight: 400;
}

.src-components-CloudStore-RenewOrder-BankTransferRenew-BankTransferRenew-module__container--4Lt5e p {
  margin-right: 10px;
  font-weight: 600;
}

.src-components-CloudStore-RenewOrder-PayPalRenew-PayPalRenew-module__container--2ukEo .paypal-button {
  width: 200px;
}

.src-components-SdkManagement-SdkManagement-module__container--iyWO8 .src-components-SdkManagement-SdkManagement-module__list--3EcmU li {
  text-align: left;
  padding-top: 10px;
}
.src-components-SdkManagement-SdkManagement-module__container--iyWO8 .src-components-SdkManagement-SdkManagement-module__shortcuts--19llM {
  margin-top: 20px;
}
.src-components-SdkManagement-SdkManagement-module__container--iyWO8 .src-components-SdkManagement-SdkManagement-module__shortcuts--19llM .ant-table-title {
  background-color: #eaeaea;
  font-weight: 700;
  text-align: left;
}

.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .ant-collapse p {
  text-align: left;
  margin: 0;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .ant-collapse p b {
  font-size: 16px;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__backButton--3-cW6 {
  text-align: left;
  padding-bottom: 20px;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__details--1aqBs {
  text-align: left;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__details--1aqBs li {
  list-style-type: none;
  padding-top: 12px;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__details--1aqBs span {
  font-weight: 700;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__intro--21RCg * {
  text-align: left;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__shortcuts--3TBRV {
  margin-top: 20px;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__shortcuts--3TBRV .ant-table-title {
  background-color: #eaeaea;
  font-weight: 700;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__shortcuts--3TBRV .ant-table-title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__actions--1Gc_4 {
  display: flex;
  justify-content: center;
}
.src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__container--tyDph .src-components-SdkManagement-SdkManagementApp-SdkManagementApp-module__actions--1Gc_4 > * {
  margin-left: 10px !important;
}


.src-components-SdkManagement-SdkManagementApp-InAppPurchaseItems-InAppPurchaseItems-module__disabled--12vKd {
  color: gray;
  font-size: 12px;
  text-align: left;
}
.src-components-SdkManagement-SdkManagementApp-InAppPurchaseItems-InAppPurchaseItems-module__container--2K_Oa .ant-table-title {
  background-color: #eaeaea;
  font-weight: 700;
}
.src-components-SdkManagement-SdkManagementApp-InAppPurchaseItems-InAppPurchaseItems-module__container--2K_Oa .ant-table-title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.src-components-SdkManagement-SdkManagementApp-AutoRenewalItems-AutoRenewalItems-module__disabled--2tZ7X {
  color: gray;
  font-size: 12px;
  text-align: left;
}
.src-components-SdkManagement-SdkManagementApp-AutoRenewalItems-AutoRenewalItems-module__container--BtN5z .ant-table-title {
  background-color: #eaeaea;
  font-weight: 700;
}
.src-components-SdkManagement-SdkManagementApp-AutoRenewalItems-AutoRenewalItems-module__container--BtN5z .ant-table-title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .ant-collapse p {
  text-align: left;
  margin: 0;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .ant-collapse p b {
  font-size: 16px;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__backButton--2hlMD {
  text-align: left;
  padding-bottom: 20px;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__details--3mHmv {
  text-align: left;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__details--3mHmv li {
  list-style-type: none;
  padding-top: 12px;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__details--3mHmv span {
  font-weight: 700;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__intro--sBW9u * {
  text-align: left;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__shortcuts--2Dgs- {
  margin-top: 20px;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__shortcuts--2Dgs- .ant-table-title {
  background-color: #eaeaea;
  font-weight: 700;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__shortcuts--2Dgs- .ant-table-title > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__actions--39lrh {
  display: flex;
  justify-content: center;
}
.src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__container--2Zho1 .src-components-SdkManagement-SdkManagementService-SdkManagementApp-module__actions--39lrh > * {
  margin-left: 10px !important;
}


.src-components-Help-Editor-Editor-module__container--PUbDE {
  height: 100%;
  position: relative;
}
.src-components-Help-Editor-Editor-module__container--PUbDE h2 {
  text-align: left;
  padding: 10px 20px 0px;
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-editor-wrapper {
  height: calc(100% - 52px);
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-editor-main {
  max-height: unset !important;
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-image-imagewrapper {
  width: 100% !important;
  position: relative;
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-image-imagewrapper img {
  max-width: 100% !important;
  position: relative;
  display: block;
  margin: auto;
}
.src-components-Help-Editor-Editor-module__actions--17wax {
  position: relative;
  margin: 10px;
}
.src-components-Help-Editor-Editor-module__actions--17wax .ant-btn {
  margin-right: 10px;
}
.src-components-Help-Editor-Editor-module__actions--17wax .ant-select {
  margin-right: 10px;
}
.src-components-Help-Editor-Editor-module__intro--iUy8E {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.src-components-Help-Editor-Editor-module__attachments--3Fn3g {
  text-align: left;
  padding: 12px;
}
.src-components-Help-Editor-Editor-module__attachments--3Fn3g > div {
  max-width: 200px;
  display: inline-block;
  margin: 8px;
  width: 100%;
  float: left;
}

.src-components-Help-help-module__container--2W1fI {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  position: relative;
}
.src-components-Help-help-module__container--2W1fI .ant-menu-inline {
  border: none;
}
.src-components-Help-help-module__container--2W1fI .ant-input-search {
  width: calc(100% - 16px);
  margin: 0px 8px !important;
}
.src-components-Help-help-module__container--2W1fI .ant-tree-node-content-wrapper {
  height: auto !important;
}
.src-components-Help-help-module__container--2W1fI .ant-row-flex-space-between span,
.src-components-Help-help-module__container--2W1fI .ant-row-flex-space-between {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  white-space: break-spaces;
}
.src-components-Help-help-module__advancedSearch--3KoyI {
  width: calc(100% - 16px);
  margin-top: 10px;
}
.src-components-Help-help-module__menuContainer--3v7NU {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 400px;
  min-width: 240px;
}
.src-components-Help-help-module__buttonActions--3BTjV {
  visibility: hidden;
}
.src-components-Help-help-module__menu--2Gyt5 {
  padding-top: 10px;
  background: white;
  height: calc(100vh - 110px);
  width: 100%;
  position: relative;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
  overflow-x: scroll;
}
.src-components-Help-help-module__menu--2Gyt5 li {
  text-align: left;
}
.src-components-Help-help-module__menu--2Gyt5 .ant-tree-child-tree.ant-tree-child-tree-open {
  overflow-x: scroll;
}
.src-components-Help-help-module__menu--2Gyt5 .ant-tree-node-content-wrapper .nt-tree-node-content-wrapper-close {
  background: antiquewhite;
  height: auto;
  padding: 10px 0 10px;
}
.src-components-Help-help-module__categoryActions--1uuvA {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.src-components-Help-help-module__categoryActions--1uuvA button {
  margin-left: 8px;
}
.src-components-Help-help-module__buttons--1xkND {
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #e8e8e8;
  padding: 4px;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.src-components-Help-help-module__buttons--1xkND button {
  margin: 4px;
}
.src-components-Help-help-module__content--u9ucJ {
  padding-top: 10px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  background: white;
}
.src-components-Help-help-module__content--u9ucJ .rdw-editor-wrapper {
  padding: 20px;
}
.src-components-Help-help-module__flex--2CFzP {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700px) {
  .src-components-Help-help-module__container--2W1fI {
    flex-direction: column;
    width: 100%;
  }
  .src-components-Help-help-module__content--u9ucJ {
    width: 100%;
  }
  .src-components-Help-help-module__menu--2Gyt5 {
    width: 100%;
    height: 220px;
    overflow-y: scroll;
    left: 0px;
    border-right: 0em;
    border-bottom: 8px solid whitesmoke;
    max-width: unset !important;
  }
  .src-components-Help-help-module__menu--2Gyt5 .ant-input-search {
    width: calc(60% - 16px);
    margin: 0px 8px 0px -6px !important;
  }
  .src-components-Help-help-module__menu--2Gyt5 .ant-input-search + button {
    width: calc(40% - 12px);
    margin: 0px;
  }
  .src-components-Help-help-module__menuContainer--3v7NU {
    width: 100%;
    max-width: unset !important;
  }
}

.src-components-Help-AdvancedSearchModal-Results-Results-module__description--1UF35 {
  word-break: break-all;
}
.src-components-Help-AdvancedSearchModal-Results-Results-module__card--3dmTZ {
  padding: 10px;
  box-shadow: 0px 0px 10px 0px #80808038;
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.src-components-Help-AdvancedSearchModal-AdvancedSearchModal-module__searchValue--enl7s {
  font-style: italic;
  color: #97c549;
}

.src-components-PaymentsPlatform-Renewals-RenewalsDetails-RenewalsDetails-module__container--10Gcj .ant-descriptions,
.src-components-PaymentsPlatform-Renewals-RenewalsDetails-RenewalsDetails-module__container--10Gcj .ant-table-title {
  text-align: left;
}
.src-components-PaymentsPlatform-Renewals-RenewalsDetails-RenewalsDetails-module__container--10Gcj .ant-table {
  margin-top: 20px;
}

.src-components-PaymentsPlatform-Renewals-RenewalsDetails-RenewalModalCancel-RenewalModalCancel-module__user--ypHJ- {
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  font-family: roboto;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
html {
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body * {
  font-family: "Roboto", sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: #63960c !important;
  font-weight: 500 !important;
}

#scraper-aic {
  display: none;
}

:root {
  --error-color: #ff868a;
  --aic-secondary: #5c7d2b;
}
.ant-btn {
  font-weight: 700;
}
::-moz-selection {
  background: #cccccc;
}
::selection {
  background: #cccccc;
}
.ant-form-item-label {
  height: 30px;
  text-align: left;
  width: 100%;
}
.ant-form-item {
  margin-bottom: 10px;
}
.has-error {
  text-align: left;
}
.ant-tabs {
  margin-top: -20px !important;
}
.ant-tabs-bar {
  margin: 0px !important;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
  overflow: auto;
  -webkit-transition: padding 0.1s;
  transition: padding 0.1s;
  padding-top: 16px;
}
.ant-popover-inner-content {
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-inner-content p {
  max-width: 500px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}
.ant-card-head {
  text-align: left;
  background: #fbfbfb;
}
.ant-table {
  background: white;
}

